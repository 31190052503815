<div class="panel panel-default panel-noborder">
    <div class="panel-body">
        <div class="row">
            <div class="col-md-6">
                <img [src]="'assets/img/pdf-download.png'" alt="">
            </div>
            <div class="col-md-16">
                <div>
                    <div *ngIf="displayTransmissionKitUpdateDesc()">
                        <div id="transmission-kit-plein-ex-desc" class="m-l-3">{{ 'TRANSMISSION_KIT_DOWNLOAD_PLEIN_EX' | translate }}</div>
                        <div id="transmission-kit-plein-ex-desc1" class="m-l-3">{{ 'TRANSMISSION_KIT_DOWNLOAD_PLEIN_EX_POINT_1' | translate }}</div>
                        <div id="transmission-kit-plein-ex-desc2" class="m-l-3">{{ 'TRANSMISSION_KIT_DOWNLOAD_PLEIN_EX_POINT_2' | translate }}</div>
                        <div id="transmission-kit-plein-ex-desc3" class="m-l-3">{{ 'TRANSMISSION_KIT_DOWNLOAD_PLEIN_EX_POINT_3' | translate }}</div>
                    </div>
                    <button class="btn btn-primary btn-block loadable m-t-5"
                            (click)="downloadPDF()"
                            [class.loading]="loading || isRequestInProgress()"
                            [attr.disabled]="loading || isUpdatePilotAndCompleted() || (isRequestInProgress() ? true : null)">
                        <i *ngIf="loading" class="fa fa-circle-o-notch fa-spin"></i>
                        <i class="fa fa-arrow-circle-down"></i>

                        {{'TRANSMISSION_MAIL_DOWNLOAD_BTN' | translate}}
                        <span *ngIf="!isPleinEx()">
                            <br>
                            <span class="button-weight-indication">
                                {{'TRANSMISSION_MAIL_DOWNLOAD_WEIGHT' | translate}}
                            </span>
                        </span>
                    </button>
                </div>
                <div *ngIf="error !== null" class="alert alert-danger">{{'GLOBAL_ERROR_MSG' | translate}}</div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isPleinEx() && !displayTransmissionKitUpdateDesc()">
    <ol class="m-t-5">
        <li>{{'TRANSMISSION_MAIL_STEP2' | translate}}</li>
        <li>{{'TRANSMISSION_MAIL_STEP3' | translate}}</li>
        <li *ngIf="!isSourceCaisse()">{{'TRANSMISSION_MAIL_STEP4' | translate}}</li>
        <li *ngIf="isSourceCaisse()">{{'TRANSMISSION_MAIL_STEP4_CAISSE' | translate}}</li>
    </ol>

    <h3 *ngIf="!isSourceCaisse()" class="m-t-5">{{'TRANSMISSION_MAIL_ADDRESS_TITLE' | translate}}</h3>
    <h3 *ngIf="isSourceCaisse()" class="m-t-5">{{'TRANSMISSION_MAIL_ADDRESS_TITLE_CAISSE' | translate}}</h3>
    <div class="row">
        <div class="col-md-12">
            <h4 class="h5">{{'TRANSMISSION_MAIL_ADDRESS_MONTREAL1' | translate}}</h4>
            <address>
                {{ 'TRANSMISSION_MAIL_ADDRESS_MONTREAL2' | translate }}<br/>
                {{ 'TRANSMISSION_MAIL_ADDRESS_MONTREAL3' | translate }}
            </address>
        </div>
        <div *ngIf="!isSourceCaisse()" class="col-md-12">
            <h4 class="h5">{{'TRANSMISSION_MAIL_ADRESS_QUEBEC1' | translate}}</h4>
            <address>
                {{ 'TRANSMISSION_MAIL_ADRESS_QUEBEC2' | translate }}<br/>
                {{ 'TRANSMISSION_MAIL_ADRESS_QUEBEC3' | translate }}<br/>
                {{ 'TRANSMISSION_MAIL_ADRESS_QUEBEC4' | translate }}
            </address>
        </div>
    </div>
</div>

<div class="btn-resp btn-resp-1colonne-inline m-t-4">
    <div class="btn-resp-col btn-resp-demi">
        <button *ngIf="!isPleinEx() && !displayTransmissionKitUpdateDesc()"
                type="submit"
                class="btn btn-primary loadable"
                [class.loading]="false"
                [attr.disabled]="disableNext ? true : null"
                (click)="navNext()">
            {{'BTN_NEXT' | translate}}
        </button>
    </div>

    <div class="btn-resp-col btn-resp-demi">
        <button *ngIf="showBack() && !isEsignDisabled()" type="button" class="btn btn-default"
                (click)="navBack()">{{'BTN_BACK' | translate}}</button>
    </div>
</div>
