import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {FormBuilder} from '@angular/forms';
import {NavigationService} from '../../components/services/navigation.service';
import {TranslateService} from '@ngx-translate/core';
import {FormService} from '../../components/services/form.service';
import {WebException} from '../../components/models';
import {ModalService} from '../../components/services/modal.service';
import {FormsComparisonService} from '../../components/services/forms-comparison.service';
import {VmdConstants} from '../../components/constants/vmd-constants';

@Component({
    selector: 'app-transmission-kit-download',
    templateUrl: './transmission-kit-download.component.html'
})
export class TransmissionKitDownloadComponent extends BaseNavComponent implements OnInit {

    loading = false;
    error: WebException = null;
    disableNext = false;
    isSaving = false;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private modalService: ModalService,
                private formsComparisonService: FormsComparisonService) {
        super(navigationService, fb, formService, translate);

    }

    ngOnInit() {

        this.navigationService.emitIsBackDisabled(true);
        this.subscriptions.push(this.formService.isSavingEmitted.subscribe((isSaving) => {
            this.isSaving = isSaving;
        }));
    }

    isEsignDisabled(): boolean {
        return this.formService.isEsignDisabled();
    }

    downloadPDF(): void {
        if (this.formService.clientIsResidentOfQuebec() && this.formService.isClientTypeOtherAccount()) {
            this.modalService.showCuratorshipResidentQuebecModal();
            return;
        }
        if (!this.formService.saveInProgress(true)) {
            this.formService.setFormProperty('transmissionMethod', this.constants.TRANSMISSION_MAIL);

            this.modalService.openModal(null, null, '#modal-pdf');
            this.modalService.emitPdfLoading(true);

            if (this.isPleinEx() || this.isUpdateAccount()) {
                this.formService.setFormProperty('transmissionMethod', this.constants.TRANSMISSION_PRINT); // OCE-4142
                this.printForm();
            } else {
                this.transmitForm();
            }
        }
    }

    printForm(): void {
        this.setLoading(true);
        this.error = null;

        this.formService.printForm().subscribe(() => {
                this.disableNext = false;
                this.setLoading(false);
                this.error = null;

                this.formService.setPrinted(true);
                this.modalService.emitPdfLink();
            },
            (error: WebException) => {
                this.setLoading(false);
                this.error = error;
                this.modalService.emitError('GLOBAL_ERROR_MSG');
            });
    }

    transmitForm(): void {
        this.setLoading(true);
        this.error = null;

        this.formService.transmitForm().subscribe(() => {
                this.disableNext = false;

                this.setLoading(false);
                this.error = null;

                this.formService.setTransmitted(true);

                this.modalService.emitPdfLink();
            },
            (error: WebException) => {
                this.setLoading(false);
                this.error = error;
                this.modalService.emitError('GLOBAL_ERROR_MSG');
            });
    }

    isSourceCaisse(): boolean {
        return this.formService.isCaisseContext();
    }

    // Set loading true and global isSaving from formService at true used to block some user actions
    setLoading(loading: boolean): void {
        this.loading = loading;
        this.formService.emitIsSaving(loading);
    }

    displayTransmissionKitUpdateDesc(): boolean {
        return this.isUpdateAccount() && this.isUpdatePilotMember()
            && (this.isUpdateDateBeforeMaxDate() || this.formsComparisonService.getSignificantChangeState() ||
                this.formService.notComparableSignificantInformationDetected(this.formService.getForm().requesters[0]) ||
                this.formService.getForm().significantChange || (this.formService.getForm().requestUpdatePath === VmdConstants.PATH_IMPRESSION));
    }

    isUpdatePilotAndCompleted(): boolean {
        const form = this.formService.getForm();
        return this.isUpdatePilotMember() && VmdConstants.MODES.AUTOMATED === form.entryMode
            && this.formService.isCompleted();
    }
}
