import {Component} from '@angular/core';
import {AccountTransferSecurity} from '../../components/models/accountTransferSecurity';
import {AccountTransferRequest} from '../../components/models';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../components/services/navigation.service';
import {FormService} from '../../components/services/form.service';
import {FileValidatorService} from '../../components/services/file-validator.service';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';

@Component({
    selector: 'app-base-transfer',
    template: ''
})


export class BaseTransferComponent extends BaseNavComponent {

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                protected fileValidatorService: FileValidatorService) {
        super(navigationService, fb, formService, translate);
    }

    getAccountTransferList(type: string) {
        if (type === this.constants.ACCOUNT_TRANSFER_CASH) {
            if (!this.formService.getForm().accountTransferList) {
                this.formService.getForm().accountTransferList = [];
            }
            return this.formService.getForm().accountTransferList;
        } else if (type === this.constants.ACCOUNT_TRANSFER_RRSP) {
            if (!this.formService.getForm().rrspAccountTransferList) {
                this.formService.getForm().rrspAccountTransferList = [];
            }
            return this.formService.getForm().rrspAccountTransferList;
        } else if (type === this.constants.ACCOUNT_TRANSFER_TFSA) {
            if (!this.formService.getForm().tfsaAccountTransferList) {
                this.formService.getForm().tfsaAccountTransferList = [];
            }
            return this.formService.getForm().tfsaAccountTransferList;
        } else if (type === this.constants.ACCOUNT_TRANSFER_FHSA) {
            if (!this.formService.getForm().fhsaAccountTransferList) {
                this.formService.getForm().fhsaAccountTransferList = [];
            }
            return this.formService.getForm().fhsaAccountTransferList;
        } else if (type === this.constants.ACCOUNT_TRANSFER_RESP) {
            if (!this.formService.getForm().respAccountTransferList) {
                this.formService.getForm().respAccountTransferList = [];
            }
            return this.formService.getForm().respAccountTransferList;
        } else if (type === this.constants.ACCOUNT_TRANSFER_LIRA) {
            if (!this.formService.getForm().liraAccountTransferList) {
                this.formService.getForm().liraAccountTransferList = [];
            }
            return this.formService.getForm().liraAccountTransferList;
        } else if (type === this.constants.ACCOUNT_TRANSFER_RRIF) {
            if (!this.formService.getForm().rrifAccountTransferList) {
                this.formService.getForm().rrifAccountTransferList = [];
            }
            return this.formService.getForm().rrifAccountTransferList;
        } else if (type === this.constants.ACCOUNT_TRANSFER_LIF) {
            if (!this.formService.getForm().lifAccountTransferList) {
                this.formService.getForm().lifAccountTransferList = [];
            }
            return this.formService.getForm().lifAccountTransferList;
        }
    }

    get transferGroup(): FormArray {
        return this.form.get('transferGroup') as FormArray;
    }

    buildTransfer(transfer: AccountTransferRequest): FormGroup {
        const transferForm = this.fb.group({
            actTypeCurrency: [transfer.actTypeCurrency, this.requiredValidator('actTypeCurrency')],
            bankName: [transfer.bankName, this.requiredValidator('bankName')],
            bankResourceFirstName: [transfer.bankResourceFirstName, this.requiredValidator('bankResourceFirstName')],
            bankResourceLastName: [transfer.bankResourceLastName, this.requiredValidator('bankResourceLastName')],
            bankPhone: [transfer.bankPhone, [this.requiredValidator('bankPhone'), VmdValidators.phone]],
            addressGroup: this.fb.group({
                addressStreet: [transfer.addressStreet, VmdValidators.addressStreetValidator(this.formService.isFieldRequired('addressStreet'))],
                addressUnit: [transfer.addressUnit, [this.requiredValidator('addressUnit'), VmdValidators.alphanumericWithSpace]],
                addressCity: [transfer.addressCity, this.requiredValidator('addressCity')],
                addressProv: [transfer.addressProv, this.requiredValidator('addressProv')],
                addressPostalCode: [transfer.addressPostalCode, [this.requiredValidator('addressPostalCode')]],
                addressCountry: [transfer.addressCountry, [this.requiredValidator('addressCountry')]]
            }, {validator: [VmdValidators.baseTransferAdressZipCodeValidator, this.requiredGroupValidator(VmdValidators.baseTransferAddressZipCodeRequired)]}),
            accountGroup: this.fb.group({
                accountNumber: [transfer.accountNumber, [this.requiredValidator('accountNumber'), VmdValidators.alphanumericWithDash]],
                accountType: [transfer.accountType, this.requiredValidator('accountType')],
                accountCurrency: [transfer.accountCurrency, this.requiredValidator('accountCurrency')]
            }),
            accountGroupSec: this.fb.group({
                accountTypeSec: [transfer.accountTypeSec, this.requiredValidator('accountTypeSec')],
                accountNumberSec: [transfer.accountNumberSec, this.requiredValidator('accountNumberSec')],
                accountCurrencySec: [transfer.accountCurrencySec, this.requiredValidator('accountCurrencySec')]
            }),
            transferType: [transfer.transferType, this.requiredValidator('transferType')],
            transferInstructionType: [transfer.transferInstructionType, this.requiredValidator('transferInstructionType')],
            transferCash: [transfer.transferCash, [this.requiredValidator('transferCash'), Validators.max(999999999)]],
            securityGroup: this.fb.array([]),
            accountStatementName: [transfer.accountStatement.name, [this.requiredValidator('accountStatement'), VmdValidators.fileFormatValidator(['pdf']), (control: AbstractControl) => this.fileValidatorService.fileSizeValidator(control)]],
            accountStatementContent: [transfer.accountStatement.content],
            uploadFile: []
        });

        const securityGroup = transferForm.get('securityGroup') as FormArray;
        if (transfer.securities.length) {
            for (const security of transfer.securities) {
                if (security) {
                    securityGroup.push(this.buildSecurity(security));
                }
            }
            if (transfer.securities.length % 5 !== 0) {
                const mod = transfer.securities.length % 5;
                for (let i = 0; i < 5 - mod; i++) {
                    const newSecurity = new AccountTransferSecurity();
                    transfer.securities.push(newSecurity);
                    securityGroup.push(this.buildSecurity(newSecurity));
                }
            }
        } else {
            for (let i = 0; i < 5; i++) {
                const newSecurity = new AccountTransferSecurity();
                transfer.securities.push(newSecurity);
                securityGroup.push(this.buildSecurity(newSecurity));
            }
        }


        return transferForm;
    }

    buildSecurity(security: AccountTransferSecurity): FormGroup {
        return this.fb.group({
            description: [security.description, this.requiredValidator('description')],
            symbol: [security.symbol, this.requiredValidator('symbol')],
            quantity: [security.quantity, [this.requiredValidator('quantity'), Validators.max(9999999999.99999)]],
            type: [security.type, this.requiredValidator('type')]
        }, {validator: VmdValidators.securityValidator});
    }

    addTransfer(transfer: AccountTransferRequest) {
        this.transferGroup.push(this.buildTransfer(transfer));
    }

    addNewTransfer(type: string) {
        // add to global object
        const transfer = new AccountTransferRequest({actType: type});
        this.getAccountTransferList(type).push(transfer);
        this.addTransfer(transfer);

        return false;
    }

    getAccountTypeList(type: string): HtmlSelectKV[] {
        let keys: string[];
        if (type === this.constants.ACCOUNT_TYPE_CASH) {
            keys = this.constants.NON_REG_ACCOUNT_TYPES;
        } else {
            keys = [type];
        }

        return this.getTypeList('ACCOUNT_FORM_ACCOUNT_OPTION_', keys);
    }

    getSecurityTypeList(): HtmlSelectKV[] {
        return this.getTypeList('TRANSFER_FORM_SECURITY_TYPE_', this.constants.SECURITY_TYPE);
    }

    private getTypeList(prefix: string, keys: string[]): HtmlSelectKV[] {
        const list: HtmlSelectKV[] = [];
        for (const key of keys) {
            list.push({
                itemValue: key,
                itemLabel: prefix + key
            });
        }
        return list;
    }

}
