import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {AttachedDocument} from '../models';

@Injectable()
export class AttachedDocumentService {

    private emitLoadedSource = new Subject<any>();
    loadedEmitted = this.emitLoadedSource.asObservable();

    emitLoaded() {
        this.emitLoadedSource.next();
    }

    public onFileChange(event, doc: AttachedDocument, nameControl: AbstractControl, contentControl: AbstractControl): void {
        const fileReader = new FileReader();
        if (event && event.target && event.target.files && event.target.files.length > 0) {
            const attachedFile = event.target.files[0];
            fileReader.readAsDataURL(attachedFile);
            fileReader.onload = () => {
                doc.name = attachedFile.name;
                // convert size to kb
                doc.size = Math.round(attachedFile.size).toString();
                doc.content = (fileReader.result as string).split(',')[1];
                nameControl.setValue(doc.name);
                contentControl.setValue(doc.content);

                this.emitLoaded();
            };
        }
    }
}
