import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ODCForm} from '../models/ODCForm';
import {VmdConstants} from '../constants/vmd-constants';
import {I18N_NAV_MESSAGES_FR} from '../i18n/nav-messages_fr';
import {I18N_NAV_MESSAGES_EN} from '../i18n/nav-messages_en';
import {TranslateService} from '@ngx-translate/core';
import {RecoveryBean} from '../models/recoveryBean';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {FormService} from './form.service';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {PersonalInformationService} from './personal-information.service';
import {Requester} from '../models/requester';
import {ModalService} from './modal.service';
import {RecoveryRedirectService} from './recovery-redirect.service';
import {Functions} from '../utils/functions';
import {VmdFormat} from '../formats/vmd-format';
import {I18N_MESSAGES_FR} from '../i18n/messages_fr';
import {I18N_MESSAGES_EN} from '../i18n/messages_en';
import {FormsComparisonService} from './forms-comparison.service';

@Injectable()
export class RecoveryService {

    constructor(private translate: TranslateService, private formService: FormService,
                private modalService: ModalService,
                private personalInformationService: PersonalInformationService,
                private router: Router, private http: HttpClient,
                private recoveryRedirectService: RecoveryRedirectService,
                private requestDiff: FormsComparisonService,
                @Inject(ODC_CONFIG) private config: IOdcConfig) {

    }

    public authenticate(recoveryBean: RecoveryBean): Observable<any> {

        return this.http.post('api/authenticate', recoveryBean);
    }

    public recover(reqid: number): Observable<any> {

        return this.http.get('api/recover/' + reqid);
    }

    public recoverFromEncryptedUrl(encryptedUrl: string): Observable<any> {
        return this.http.post('api/recover', JSON.stringify(encryptedUrl));
    }

    public retrievePassword(): Observable<any> {

        return this.http.get('api/retrieve-password');
    }

    getUrl(path: string): string {

        let url: string = I18N_NAV_MESSAGES_FR[path];

        if (this.translate.currentLang === VmdConstants.LANG_EN) {

            url = I18N_NAV_MESSAGES_EN[path];
        }

        return url;
    }

    redirectToCorrectPath(form: ODCForm) {

        let path;

        if (!this.config.IS_PLEIN_EX) {
            if (form.newExistAct === VmdConstants.ACCOUNT_UPDATE_CLIENT &&
                form.cltType === VmdConstants.CLT_TYPE_INDIVIDU) {
                path = this.recoveryRedirectService.getPersonalUpdateAccountDisnatWebPath(form);
            } else {
                path = this.getDisnatPath(form);
            }

        } else {
            if (form.cltType === VmdConstants.CLT_TYPE_INDIVIDU) {
                if (form.newExistAct === VmdConstants.ACCOUNT_UPDATE_CLIENT) {
                    path = this.recoveryRedirectService.getPersonalUpdateAccountPleinExPath(form);
                } else {
                    path = this.recoveryRedirectService.getPersonalAccountPleinExPath(form);
                }
            } else if (form.cltType === VmdConstants.CLT_TYPE_CONJOINT) {
                path = this.recoveryRedirectService.getJointAccountPleinExPath(form);
            } else {
                path = this.recoveryRedirectService.getOtherAccountPleinExPath(form);
            }
        }

        if (null !== path) {
            const url = this.getUrl(path);
            this.router.navigate([url]);
        }
    }

    onRecoverySuccess(data: any): void {

        this.formService.initForm(data.payload);
        this.initSessionBroker(this.formService.getForm());
        this.setModeForUpdatePilot();

        // set recovered request lang
        if (!this.config.IS_PLEIN_EX && !this.config.IS_UPDATE_ACCOUNT) {
            this.translate.use(this.formService.getForm().lang);

            this.recoverIndividualCaisseRequest();
        }

        if (this.config.IS_UPDATE_ACCOUNT) {
            if (this.config.IS_UPDATE_PILOT_MEMBER) {
                if (this.recoveryRedirectService.isNotStatusCompleted(this.formService.getForm())) {
                    this.displayPilotUpdatePopUp();
                }
            }
            this.formService.applyRules();
        }

        this.hideLanguageSwitch();

        this.redirectToCorrectPath(this.formService.getForm());
    }

    initSessionBroker(odcForm: ODCForm) {
        sessionStorage.setItem('userEmployerIsBroker', odcForm.requesters[0].userEmployerIsBroker);
    }

    private setModeForUpdatePilot() {
        if (this.config.IS_UPDATE_ACCOUNT) {
            if (this.config.IS_UPDATE_PILOT_MEMBER) {
                this.formService.setMode(VmdConstants.MODES.AUTOMATED);
            } else {
                this.formService.setMode(VmdConstants.MODES.PRINT);
            }
        }
    }

    private recoverIndividualCaisseRequest() {
        if (!this.formService.isFromSmartD() && this.formService.isCaisseContext() && VmdConstants.CLT_TYPE_INDIVIDU === this.formService.getForm().cltType) {

            this.personalInformationService.obtenirInformationPartiePrenante(this.config.BRANCH_ENCRYPTED_URL).subscribe((res) => {

                if (res.payload !== null) {
                    this.initDdpRequester(res);
                }
            }, () => {
                this.modalService.openModal('PERSONAL_INFORMATION_FAILED_MODAL_TITLE', 'PERSONAL_INFORMATION_FAILED_MODAL_BODY');
            });

        }
    }

    private initDdpRequester(res) {
        this.formService.setDdpRequester(new Requester(res.payload));

        if (this.formService.getDdpRequester().consent && !Functions.isNullOrEmpty(this.formService.getDdpRequester().formLang)) {
            this.formService.getForm().lang = this.formService.getDdpRequester().formLang;
            if (this.translate.currentLang !== this.formService.getDdpRequester().formLang) {
                this.translate.use(this.formService.getDdpRequester().formLang);
            }
        }

        if (this.formService.getForm().requestStatus === VmdConstants.REQUEST_STATUS_SAVED) {

            this.updateConsentInformations(this.formService.getDdpRequester());
        }
    }

    private displayPilotUpdatePopUp() {
        const updateDate = this.formService.getForm().mostRecentFormDate ? new Date(this.formService.getForm().mostRecentFormDate) : null;
        const maxUpdateDate = new Date(VmdConstants.LAST_UPDATE_DATE_MAX);

        const updateDateFormatted = updateDate ? VmdFormat.date.formatAsStandardDate(updateDate.toDateString()) : this.getNoDateMessage();

        if (updateDate && updateDate > maxUpdateDate) {
            this.modalService.openModal(null, 'updateDate', '#modal-alert-most-recent-form-date-after', null, updateDateFormatted);
        } else {
            this.formService.getForm().includeInvestorProfile = true;
            this.modalService.openModal(null, 'updateDate', '#modal-alert-most-recent-form-date', null, updateDateFormatted);
        }
    }

    private getNoDateMessage() {
        if (this.translate.currentLang === VmdConstants.LANG_EN) {
            return I18N_MESSAGES_EN.NO_AS400_DATE;
        }

        return I18N_MESSAGES_FR.NO_AS400_DATE;
    }

    private getDisnatPath(form: ODCForm): string {
        if (form.cltType === VmdConstants.CLT_TYPE_INDIVIDU && form.srcCde !== VmdConstants.SOURCE_CAISSE) {
            return this.recoveryRedirectService.getPersonalAccountDisnatWebPath(form);
        }

        if (form.cltType === VmdConstants.CLT_TYPE_INDIVIDU && form.srcCde === VmdConstants.SOURCE_CAISSE) {
            return this.recoveryRedirectService.getPersonalAccountDisnatCaissePath(form);
        }

        if (form.cltType === VmdConstants.CLT_TYPE_CONJOINT && form.srcCde === VmdConstants.SOURCE_CAISSE) {
            return this.recoveryRedirectService.getJointAccountDisnatCaissePath(form);
        }

        if (form.cltType !== VmdConstants.CLT_TYPE_INDIVIDU && form.cltType !== VmdConstants.CLT_TYPE_CONJOINT && form.srcCde === VmdConstants.SOURCE_CAISSE) {
            return this.recoveryRedirectService.getOtherAccountDisnatCaissePath(form);
        }

        return '';
    }

    updateConsentInformations(ddpRequester: Requester) {
        const requesterForm = this.formService.getForm().requesters[0];

        requesterForm.formLang = ddpRequester.formLang ? ddpRequester.formLang : requesterForm.formLang;
        requesterForm.gender = ddpRequester.gender ? ddpRequester.gender : requesterForm.gender;
        requesterForm.userFirstName = ddpRequester.userFirstName ? ddpRequester.userFirstName : requesterForm.userFirstName;
        requesterForm.userLastName = ddpRequester.userLastName ? ddpRequester.userLastName : requesterForm.userLastName;
        requesterForm.userDOB = ddpRequester.userDOB ? ddpRequester.userDOB : requesterForm.userDOB;

        requesterForm.userAddressStreet = ddpRequester.userAddressStreet ? ddpRequester.userAddressStreet : requesterForm.userAddressStreet;
        requesterForm.userAddressUnit = ddpRequester.userAddressUnit ? ddpRequester.userAddressUnit : requesterForm.userAddressUnit;
        requesterForm.userAddressCity = ddpRequester.userAddressCity ? ddpRequester.userAddressCity : requesterForm.userAddressCity;
        requesterForm.userAddressProv = ddpRequester.userAddressProv ? ddpRequester.userAddressProv : requesterForm.userAddressProv;
        requesterForm.userAddressPostalCode = ddpRequester.userAddressPostalCode ? ddpRequester.userAddressPostalCode : requesterForm.userAddressPostalCode;

        requesterForm.userBankId = ddpRequester.userBankId ? ddpRequester.userBankId : requesterForm.userBankId;
        requesterForm.userBankBranchNumber = ddpRequester.userBankBranchNumber ? ddpRequester.userBankBranchNumber : requesterForm.userBankBranchNumber;

        requesterForm.userHomePhone = ddpRequester.userHomePhone ? ddpRequester.userHomePhone : requesterForm.userHomePhone;

        if (requesterForm.consent || ddpRequester.consent) {

            requesterForm.userSIN = ddpRequester.userSIN;
            requesterForm.userOtherPhone = ddpRequester.userOtherPhone;
            requesterForm.userEmail = ddpRequester.userEmail;

            requesterForm.userAddressStreetSec = ddpRequester.userAddressStreetSec;
            requesterForm.userAddressUnitSec = ddpRequester.userAddressUnitSec;
            requesterForm.userAddressCitySec = ddpRequester.userAddressCitySec;
            requesterForm.userAddressProvSec = ddpRequester.userAddressProvSec;
            requesterForm.userAddressPostalCodeSec = ddpRequester.userAddressPostalCodeSec;

            requesterForm.userCitizenship = ddpRequester.userCitizenship;
            requesterForm.userJobStatus = ddpRequester.userJobStatus;
            requesterForm.userEmployerName = ddpRequester.userEmployerName;
            requesterForm.userJobTitle = ddpRequester.userJobTitle;
            requesterForm.userEmployerActivity = ddpRequester.userEmployerActivity;

            requesterForm.userMaritalStatus = ddpRequester.userMaritalStatus;
            requesterForm.spouseGender = ddpRequester.spouseGender;
            requesterForm.spouseFirstName = ddpRequester.spouseFirstName;
            requesterForm.spouseLastName = ddpRequester.spouseLastName;
            requesterForm.spouseDOB = ddpRequester.spouseDOB;
        }

        requesterForm.consent = ddpRequester.consent;
    }

    hideLanguageSwitch(): void {
        const requestStatus = this.formService.getForm().requestStatus;

        if (requestStatus === VmdConstants.REQUEST_STATUS_TRANSMIT || requestStatus === VmdConstants.REQUEST_STATUS_COMPLETE
            || this.config.TO_CORRECT
            || this.isStatusInjection()) {

            if (!this.formService.isEditableConvertedRequest()) {

                this.formService.setCompleted(true);
            }
        }
    }

    isStatusInjection(): boolean {
        const requestStatus = this.formService.getForm().requestStatus;

        return requestStatus === VmdConstants.REQUEST_STATUS_APPROVAL ||
            requestStatus === VmdConstants.REQUEST_STATUS_AUTHORIZE ||
            requestStatus === VmdConstants.REQUEST_STATUS_FORCED ||
            requestStatus === VmdConstants.REQUEST_STATUS_AUTO ||
            requestStatus === VmdConstants.REQUEST_STATUS_INJECTED ||
            requestStatus === VmdConstants.REQUEST_STATUS_PROCESS ||
            requestStatus === VmdConstants.REQUEST_STATUS_READY ||
            requestStatus === VmdConstants.REQUEST_STATUS_REJECTED ||
            requestStatus === VmdConstants.REQUEST_STATUS_TOCOMPLETE ||
            requestStatus === VmdConstants.REQUEST_STATUS_VALIDATE ||
            requestStatus === VmdConstants.REQUEST_STATUS_FINALIZED ||
            requestStatus === VmdConstants.REQUEST_STATUS_MANUAL ||
            requestStatus === VmdConstants.REQUEST_STATUS_DECLINED ||
            requestStatus === VmdConstants.REQUEST_STATUS_CANCELLED;
    }
}
