<form (submit)="submit()" [formGroup]="form" ngForm novalidate>

    <div *ngIf="isNotConjoint() || isConjointPleinEx()" class="m-b-5">

        <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

        <h2>{{ 'ACCOUNT_CHOICE_UPDATE_TITLE' | translate }}</h2>

        <fieldset class="form-group">
            <legend class="sr-only"><b>{{ 'ACCOUNT_CHOICE_TITLE' | translate }}</b></legend>
            <div class="panel panel-default panel-noborder" id="panelDefault">
                <div class="panel-body">
                    <!--update account/new account/add account-->
                    <app-odc-field
                        [attrDisabled]="true"
                        [binding]="'newExistAct'"
                        [choices]="[{label: 'UPDATE_ACCOUNT' | translate, value: constants.ACCOUNT_UPDATE_CLIENT},
                        {label: 'NEW_ACCOUNT' | translate, value: constants.ACCOUNT_NEW_CLIENT},
                        {label: 'ADD_ACCOUNT' | translate, value: constants.ACCOUNT_ADD_ACCOUNT}]"
                        [errorOnSubmitOnly]="['pattern']"
                        [form]="form"
                        [id]="'newExistAct'"
                        [label]="'REQUEST_TYPE' | translate"
                        [name]="'newExistAct'"
                        [path]="'newExistAct'"
                        [stacked]="true"
                        [submitted]="isFormSubmitted()"
                        [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>

                    <!--cash account/margin account-->
                    <div class="m-t-3">
                        <app-odc-field
                            *ngIf="!isGpdOrSfd()"
                            [attrDisabled]="true"
                            [binding]="'actType'"
                            [choices]="[{label: 'CASH_ACCOUNT' | translate, value: constants.ACCOUNT_TYPE_CASH},
                                        {label: 'MARGIN_ACCOUNT' | translate, value: constants.ACCOUNT_TYPE_MARGIN}]"
                            [form]="form"
                            [id]="'actType'"
                            [label]="'ACCOUNT_TYPE' | translate"
                            [name]="'actType'"
                            [path]="'actType'"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>
                    </div>

                    <div *ngIf="showMarginAccount() && form.get('actType').value === constants.ACCOUNT_TYPE_MARGIN"
                         class="m-l-4">

                        <!--optionAct/shortSellAct-->
                        <app-odc-field
                            *ngIf="!isGpdOrSfd()"
                            [attrDisabled]="true"
                            [choices]="[{label: 'WITH_OPTIONS' | translate, id: 'optionAct'}, {label: 'WITH_SHORT_SELLING' | translate, id: 'shortSellAct', hidden: showMarginAccountWithShortSelling()}]"
                            [form]="form.get('marginAccount')"
                            [label]="'ACCOUNT_TYPE' | translate"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.CHECKBOX"
                        ></app-odc-field>
                    </div>

                    <div
                        *ngIf="showManagementTypeChoices()"
                        class="m-1-4">
                        <app-odc-field
                            [type]="constants.FIELD_TYPES.RADIO"
                            [form]="form"
                            [attrDisabled]="true"
                            [submitted]="isFormSubmitted()"
                            [label]="'MGNT_TYPE' | translate"
                            [choices]="[
                                {
                                    label: 'MGNT_TYPE_COM' | translate,
                                    value: constants.MGNT_TYPE_COM,
                                    hidden: isGpdOrSfd()
                                },
                                {
                                    label: (isSsd() ? 'PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_SSD' : 'PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM') | translate,
                                    value: constants.MGNT_TYPE_ASSET,
                                    hidden: isGpdOrSfd()
                                },
                                {
                                    label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                    value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                }]"
                            [id]="'cashAccountMgntType'"
                            [name]="'cashAccountMgntType'"
                            [path]="'cashAccountMgntType'"
                            [binding]="'cashAccountMgntType'"
                            [stacked]="true"
                            [errorOnSubmitOnly]="['invalidMgntType']"
                        ></app-odc-field>
                    </div>

                    <div class="m-t-3">
                        <!--currency-->
                        <app-odc-field
                            [attrDisabled]="true"
                            [binding]="'currency'"
                            [choices]="[{label: 'CA_ONLY' | translate, value: constants.CURRENCY_CAD},
                            {label: 'US_AND_CA' | translate, value: constants.CURRENCY_USD}]"
                            [form]="form"
                            [id]="'currency'"
                            [label]="'ACCOUNT_CURRENCY' | translate"
                            [name]="'currency'"
                            [path]="'currency'"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>

    <app-questionnaire-btn [formRef]="formRef" [form]="form" [hideBackBtn]="hideBackBtn()"></app-questionnaire-btn>
</form>
