import {BaseNavComponent} from '../../layout/questionnaire/base-nav.component';
import {Component, OnInit} from '@angular/core';
import {NavigationService} from '../../../components/services/navigation.service';
import {FormBuilder} from '@angular/forms';
import {FormService} from '../../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {WebException} from '../../../components/models';
import {FormsComparisonService} from '../../../components/services/forms-comparison.service';
import {RequestsComparisonResult} from '../../../components/models/requests-comparison-result.model';
import {ModalService} from '../../../components/services/modal.service';

@Component({
    selector: 'app-review-update-account',
    templateUrl: './review-update-account.component.html'
})
export class ReviewUpdateAccountComponent extends BaseNavComponent implements OnInit {

    formDelta: RequestsComparisonResult[];

    loading = true;
    isSaving = false;
    error: WebException = null;
    alreadySubmitError: string = null;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private modalService: ModalService,
                private formsComparisonService: FormsComparisonService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit(): void {

        this.loading = true;
        this.formService.emitIsSaving(true);
        this.error = null;
        if (!this.formService.isCompleted() && !this.formService.isPrinted()) {
            this.alreadySubmitError = null;
            this.formService.compareSavedForm().subscribe((data: any) => {
                    const results: RequestsComparisonResult[] = data.payload;

                    if (Object.keys(results).length > 0) {
                        this.formsComparisonService.updateComparisonResults$.next(results);
                    } else {
                        this.formsComparisonService.updateComparisonResults$.next([]);
                    }

                    this.retrieveFormDifferences();

                    this.loading = false;
                    this.formService.emitIsSaving(false);
                },
                (error: WebException) => {
                    this.showError();
                    this.error = error;
                    this.loading = false;
                    this.formService.emitIsSaving(false);
                });
        } else {
            this.alreadySubmitError = 'ODC_QUESTIONNAIRE_SAVE_ERROR_ALREADY_COMPLETED_COMPARE_SAVED_FORM';
            this.loading = false;
            this.formService.emitIsSaving(false);
        }
    }

    showError() {
        this.modalService.openModal('GLOBAL_ERROR',
            'ODC_SEARCH_CLIENT_INFORMATION_OSS_ERROR',
            '#modal-alert-error', null);
    }

    private retrieveFormDifferences() {
        this.formDelta = this.formsComparisonService.getComparisonResults();
    }
}
