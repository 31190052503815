<div class="panel panel-default panel-noborder">
    <div class="panel-body">
        <form *ngIf="!submitted" [formGroup]="form" ngForm novalidate (submit)="submitForm()">

            <!--confirm-->
            <app-odc-field
                [type]="constants.FIELD_TYPES.CHECKBOX"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_NAV_STEP_SUBMIT' | translate"
                [labelSrOnly]="true"
                [choices]="[{label: (isUpdateAccount() && isUpdatePilotMember() ? 'SUBMIT_PLEIN_EX_CONFIRM' : 'SUBMIT_CONFIRM') |
                    translate, id: 'confirm', binding: ''}]"
                [stacked]="true">
            </app-odc-field>

            <div>
                <button type="submit"
                        class="btn btn-primary btn-block loadable"
                        [class.loading]="loading"
                        [attr.disabled]="(loading || !form.get('confirm').value) ? true : null">
                    <i *ngIf="loading" class="fa fa-circle-o-notch fa-spin"></i>
                    <i class="fa fa-check"></i>
                    {{'BTN_SUBMIT' | translate}}
                </button>
            </div>
            <div *ngIf="alreadySubmitError !== null" class="alert alert-danger">{{alreadySubmitError | translate}}</div>
            <div *ngIf="error !== null" class="alert alert-danger">{{'GLOBAL_ERROR_MSG' | translate}}</div>

        </form>

        <div *ngIf="submitted">
            {{submitLabel}}
        </div>
    </div>
</div>

<div *ngIf="!submitted" class="btn-resp btn-resp-1colonne-inline m-t-4">
    <div class="btn-resp-col btn-resp-demi">
        <button type="button" class="btn btn-default" (click)="navBack()">{{'BTN_BACK' | translate}}</button>
    </div>
</div>

