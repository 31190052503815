import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AccountRulesService} from '../../../components/services/account-rules.service';
import {FormService} from '../../../components/services/form.service';
import {ListModelService} from '../../../components/services/list-model.service';
import {ModalService} from '../../../components/services/modal.service';
import {NavigationService} from '../../../components/services/navigation.service';
import {AccountsChoiceComponent} from '../accounts-choice.component';
import {IOdcConfig, ODC_CONFIG} from "../../../odc-config";

@Component({
    selector: 'app-accounts-choice-update',
    templateUrl: './accounts-choice-update.component.html'
})
export class AccountsChoiceUpdateComponent extends AccountsChoiceComponent implements OnInit {

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                protected router: Router,
                public formService: FormService,
                public translate: TranslateService,
                protected accountRulesService: AccountRulesService,
                protected modalService: ModalService,
                protected listModelService: ListModelService) {
        super(navigationService, fb, router, formService, translate, accountRulesService, modalService, listModelService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.hideSubTabFinancialInfos(this.formService.getForm().requesters[this.requesterIndex].userEmployerIsBroker);

        if (this.isPrintMode()) {
            this.setManagementTypeDefaultValue();
        }
    }

    setPleinExDefaultValues() {
        if (this.formService.getForm().newExistAct === null) {
            this.formService.setFormProperty('newExistAct', this.constants.ACCOUNT_UPDATE_CLIENT);
        }
    }

    public setManagementTypeDefaultValue(): void {
        const form = this.formService.getForm();
        if (this.isSsd() || (this.isPleinEx()) && !this.isGpdOrSfd()) {
            if (form.portfolioManagementMandateOnly) {
                this.form.get('cashAccountMgntType').setValue(this.constants.MGNT_TYPE_MAND);
                form.cashAccountMgntType = this.constants.MGNT_TYPE_MAND;
            } else if (form.portfolioManagementAdvisorProgram) {
                this.form.get('cashAccountMgntType').setValue(this.constants.MGNT_TYPE_ASSET);
                form.cashAccountMgntType = this.constants.MGNT_TYPE_ASSET;
            } else {
                this.form.get('cashAccountMgntType').setValue(this.constants.MGNT_TYPE_COM);
                form.cashAccountMgntType = this.constants.MGNT_TYPE_COM;
            }
        }
        if (this.isGpd()) {
            this.form.get('cashAccountMgntType').setValue(this.constants.MGNT_TYPE_COM);
            form.cashAccountMgntType = this.constants.MGNT_TYPE_COM;
        }
    }
}
