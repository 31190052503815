import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormService} from './components/services/form.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SessionService} from './components/services/session.service';
import {RecoveryService} from './components/services/recovery.service';
import {IOdcConfig, ODC_CONFIG, OdcConfig} from './odc-config';
import {WindowRefService} from './components/services/window-ref.service';
import {APP_ROUTES} from './app.routes-config';
import {VmdConstants} from './components/constants/vmd-constants';
import {I18N_NAV_MESSAGES_FR} from './components/i18n/nav-messages_fr';
import {PersonalInformationService} from './components/services/personal-information.service';
import {ModalService} from './components/services/modal.service';
import {FormsComparisonService} from './components/services/forms-comparison.service';
import {I18N_NAV_MESSAGES_EN} from './components/i18n/nav-messages_en';
import {DOCUMENT} from '@angular/common';

const MODAL_CONTEXT_OSS_ERROR = 'MODAL_CONTEXT_OSS_ERROR';

@Component({
    selector: 'app-root',
    template: '<div [class.root-loading]="rootLoading"><router-outlet></router-outlet></div>'
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {

    rootLoading = false;
    protected subscriptions: any = [];

    constructor(
        private router: Router,
        private formService: FormService,
        private translate: TranslateService,
        private sessionService: SessionService,
        private recoveryService: RecoveryService,
        private personalInformationService: PersonalInformationService,
        private windowRefService: WindowRefService,
        private modalService: ModalService,
        private formsComparisonService: FormsComparisonService,
        @Inject(ODC_CONFIG) private config: IOdcConfig,
        @Inject(DOCUMENT) private document: any
    ) {

        this.setDefaultLang();
    }

    ngOnInit() {

        if (this.config.CONSULTATION) {

            this.sessionService.getNewToken().subscribe((sessionData: any) => {

                this.sessionService.beginSession(sessionData.payload);

                if (this.config.CONSULTATION === VmdConstants.CONSULTATION_REQUEST_UPDATE_DIFF && this.config.FROM_EXISTING_REQUEST) {
                    const url = this.getConsultationPage('ODC_NAV_STEP_REVIEW_UPDATE_ACCOUNT_PATH');
                    this.router.navigate([url]);
                }
            });
        } else if (this.config.AGENT_DISNAT_USERNAME && this.config.FROM_EXISTING_REQUEST) {
            this.sessionService.getNewToken().subscribe((sessionData: any) => {
                this.sessionService.beginSession(sessionData.payload);

                this.rootLoading = true;
                this.recoveryService.recoverFromEncryptedUrl(this.config.ENCRYPTED_URL).subscribe((recoveryData: any) => {
                        this.rootLoading = false;
                        this.recoveryService.onRecoverySuccess(recoveryData);
                    },
                    () => {
                        this.rootLoading = false;
                    });
            });
        } else if (this.config.IS_UPDATE_ACCOUNT) {

            this.sessionService.getNewToken().subscribe((sessionData: any) => {

                this.sessionService.beginSession(sessionData.payload);

                this.rootLoading = true;

                this.personalInformationService.retrieveClientData(this.config.ENCRYPTED_URL).subscribe((response: any) => {

                        this.rootLoading = false;
                        this.initUpdateFormDefaultValues(response.payload);
                        this.initSessionStorageWithUserData(response.payload);
                        this.recoveryService.onRecoverySuccess(response);
                    },
                    () => {
                        this.rootLoading = false;
                        this.showError();
                    });
            });

        }

        this.subscriptions.push(this.modalService.validClickOnChoice1Emitted.subscribe((context: string) => {
            if (context === MODAL_CONTEXT_OSS_ERROR) {
                this.windowRefService.getNativeWindow().close();
            }
        }));

        this.router.resetConfig(APP_ROUTES);
    }

    ngOnDestroy() {
        this.subscriptions.each(
            subscription => {
                subscription.unsubscribe();
            }
        );
    }

    showError() {
        this.modalService.openModal('GLOBAL_ERROR',
            'ODC_SEARCH_CLIENT_INFORMATION_OSS_ERROR',
            '#modal-alert-error', MODAL_CONTEXT_OSS_ERROR);
    }

    initUpdateFormDefaultValues(odcFormBean: any) {
        this.config.IS_GPD = odcFormBean.gpd;
        this.config.IS_SFD = odcFormBean.sfd;
        this.config.IS_SSD = odcFormBean.ssd;

        odcFormBean.pleinEx = this.config.IS_PLEIN_EX;
        odcFormBean.cltType = VmdConstants.CLT_TYPE_INDIVIDU;
        odcFormBean.newExistAct = VmdConstants.ACCOUNT_UPDATE_CLIENT;

        if (this.config.IS_UPDATE_PILOT_MEMBER) {
            odcFormBean.entryMode = VmdConstants.MODES.AUTOMATED;
        }
    }

    initSessionStorageWithUserData(odcFormBean: any) {
        sessionStorage.clear();
        sessionStorage.setItem('userAddressStreet', odcFormBean.requesters[0].userAddressStreet);
        odcFormBean.requesters[0].userAddressStreet = '';
        sessionStorage.setItem('userAddressUnit', odcFormBean.requesters[0].userAddressUnit);
        odcFormBean.requesters[0].userAddressUnit = '';
        sessionStorage.setItem('userAddressCity', odcFormBean.requesters[0].userAddressCity);
        odcFormBean.requesters[0].userAddressCity = '';
        sessionStorage.setItem('userAddressProv', odcFormBean.requesters[0].userAddressProv);
        odcFormBean.requesters[0].userAddressProv = '';
        sessionStorage.setItem('userAddressPostalCode', odcFormBean.requesters[0].userAddressPostalCode);
        odcFormBean.requesters[0].userAddressPostalCode = '';
        sessionStorage.setItem('userAddressCountry', odcFormBean.requesters[0].userAddressCountry);
        odcFormBean.requesters[0].userAddressCountry = '';
    }

    ngAfterViewInit() {
        this.router.navigate(['']);
    }

    setDefaultLang() {
        const params = this.windowRefService.getLocationSearch();
        let lng = null;

        if (OdcConfig.LANGUE === 'en') {
            lng = 'en';
        } else if (OdcConfig.LANGUE === 'fr') {
            lng = 'fr';
        }

        // Set lang from Caisse
        if (params.indexOf('contexteCaisse=') !== -1 || params.indexOf('contexteCaisseV2=') !== -1) {
            lng = this.config.LANGUE;
        }

        if (lng !== null && this.translate.currentLang !== lng) {
            this.translate.use(lng);
            this.document.documentElement.lang = lng;
        }
    }

    getConsultationPage(pathId: string): string {
        return 'consultation' + '/' + (this.translate.currentLang === VmdConstants.LANG_EN ? I18N_NAV_MESSAGES_EN[pathId] : I18N_NAV_MESSAGES_FR[pathId]);
    }
}
