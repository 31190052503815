import {Inject, Injectable} from '@angular/core';
import {ODCForm} from '../models/ODCForm';
import {Observable, Subject} from 'rxjs';
import {NavigationService} from './navigation.service';
import {HttpClient} from '@angular/common/http';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {VmdConstants} from '../constants/vmd-constants';
import {SessionService} from './session.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseNavComponent} from '../../modules/layout/questionnaire/base-nav.component';
import {Requester} from '../models/requester';
import {RequiredFields} from '../constants/requiredFields';
import {Branch} from '../models/Branch';
import {AdvisorIdentity} from '../models/advisorIdentity';
import {environment} from '../../../environments/environment';
import {Functions} from '../utils/functions';
import {ModalService} from './modal.service';
import {FormsComparisonService} from './forms-comparison.service';

@Injectable()
export class FormService {

    private form: ODCForm;
    private ddpRequester = new Requester();
    private savedEmail: string = null;
    private savedPassword: string = null;
    completed = false;
    private transmitted = false;
    private printed = false;
    private constants = VmdConstants;
    isSaving = false;
    isSaveDisabled = false;

    private mode: string = VmdConstants.MODES.PRINT;

    private currentComposant: BaseNavComponent;

    private emitSavedSource = new Subject<string>();

    subjectChange = new Subject<ODCForm>();

    private emitIsSavingSource = new Subject<boolean>();
    isSavingEmitted = this.emitIsSavingSource.asObservable();

    private emitIsUpdatingFormSource = new Subject<boolean>();
    isUpdatingFormEmitted = this.emitIsUpdatingFormSource.asObservable();

    private emitModeSource = new Subject<string>();
    modeChanged = this.emitModeSource.asObservable();

    private emitBeneficiarySCEE = new Subject<boolean>();
    beneficiarySCEEEmitted = this.emitBeneficiarySCEE.asObservable();

    private ossQueryTfsaCurrency = '';

    constructor(private navigationService: NavigationService,
                private http: HttpClient,
                private translate: TranslateService,
                private sessionService: SessionService,
                private modalService: ModalService,
                private formsComparisonService: FormsComparisonService,
                @Inject(ODC_CONFIG) private config: IOdcConfig) {

        this.initForm({});

        this.formsComparisonService.significantChangeState$.subscribe((state) => {
            this.includeInvestorProfile();
            this.displayUpdateTransmissionSubmitPath(state);
            if (this.config.IS_UPDATE_ACCOUNT && this.config.IS_UPDATE_PILOT_MEMBER) {
                this.getForm().significantChange = this.isRedocumentForm();
            }
        });
    }

    private includeInvestorProfile() {
        const isUpdateAccountContext = this.config.IS_UPDATE_ACCOUNT;
        const isPilotMember = this.config.IS_UPDATE_ACCOUNT && this.config.IS_UPDATE_PILOT_MEMBER;
        const isGPD = this.config.IS_PLEIN_EX && this.config.IS_GPD;
        const isSFD = this.config.IS_PLEIN_EX && this.config.IS_SFD;
        const isSSD = this.config.IS_PLEIN_EX && this.config.IS_SSD;
        const isVMD = this.config.IS_PLEIN_EX && !isGPD && !isSSD && !isSFD;

        if (isUpdateAccountContext
            && isPilotMember
            && !isGPD
            && !isSFD
            && (isVMD || isSSD)
            && this.isRedocumentForm()) {
            this.setFormProperty('includeInvestorProfile', true);
        } else if (!isGPD && !isSFD) {
            this.setFormProperty('includeInvestorProfile', false);
        }
    }

    private isRedocumentForm(): boolean {
        return this.isFormLastUpdateDateWasBeforeDefinedMaxDate() ||
            this.formsComparisonService.getSignificantChangeState();
    }

    public getMode(): string {
        return this.mode;
    }

    public getCurrentLang(): string {
        return this.translate.currentLang;
    }

    public setMode(mode: string): void {
        this.mode = mode;
        this.emitModeSource.next(this.mode);
        this.setFormProperty('entryMode', this.mode);
    }

    public setEmitBeneficiarySCEE(val: boolean): void {
        this.emitBeneficiarySCEE.next(val);
    }

    public isPrintMode(): boolean {
        return VmdConstants.MODES.PRINT === this.getMode();
    }

    emitSaved() {
        this.emitSavedSource.next();
    }

    emitIsSaving(isSaving: boolean) {
        this.emitIsSavingSource.next(isSaving);
        this.isSaving = isSaving;
    }

    emitIsUpdatingForm(isUpdatingForm: boolean) {
        this.emitIsUpdatingFormSource.next(isUpdatingForm);
    }

    // Return depending if the parameter is present if the request is saving or processing
    public saveInProgress(isRequestProcessing?: boolean): boolean {
        if (this.isSaving) {
            this.modalService.openModal('ODC_QUESTIONNAIRE_SAVE_MODAL_INFO_TITLE',
                isRequestProcessing === true ? 'ODC_QUESTIONNAIRE_IN_PROCESS_MODAL_INFO_BODY' : 'ODC_QUESTIONNAIRE_SAVE_MODAL_INFO_BODY');
            return true;
        } else {
            return false;
        }
    }

    public saveInProgressWithoutModal(): boolean {
        return this.isSaving;
    }

    public setSavedEmail(email: string): void {
        this.savedEmail = email;
    }

    public setSavedPassword(password: string): void {
        this.savedPassword = password;
    }

    public isCompleted(): boolean {
        return this.completed;
    }

    public setCompleted(completed: boolean): void {
        this.completed = completed;
    }

    public isTransmitted(): boolean {
        return this.transmitted;
    }

    public setTransmitted(transmitted: boolean): void {
        this.transmitted = transmitted;
    }

    public setPrinted(printed: boolean): void {
        this.printed = printed;
    }

    public isPrinted(): boolean {
        return this.printed;
    }

    public getClientType(): string {

        return this.form.cltType;
    }

    public setCurrentComposant(component: BaseNavComponent): void {
        this.currentComposant = component;
    }

    public getCurrentComposant(): BaseNavComponent {
        return this.currentComposant;
    }

    public initForm(obj: any): void {

        this.completed = false;
        this.transmitted = false;
        this.savedEmail = null;
        this.savedPassword = null;

        this.form = new ODCForm(obj);
        this.setMode(this.form.entryMode);
        this.ossQueryTfsaCurrency = this.form.tfsaCurrency;

        this.applyRules();
    }

    public setForm(form: ODCForm): void {

        this.form = form;
    }

    public getDdpRequester(): Requester {

        return this.ddpRequester;
    }

    public setDdpRequester(requester: Requester): void {

        this.ddpRequester = requester;
    }

    public isFieldRequired(fieldname: string): boolean {

        if (this.form.newExistAct === VmdConstants.ACCOUNT_UPDATE_CLIENT &&
            !this.config.IS_UPDATE_PILOT_MEMBER &&
            this.isPrintMode()) {
            return false;
        }

        const division = this.config.IS_PLEIN_EX ? VmdConstants.DIVISIONS.PLEIN_EX : VmdConstants.DIVISIONS.DISNAT;

        if (fieldname && RequiredFields[fieldname]) {
            const arr = RequiredFields[fieldname][division + '_' + this.getMode()];
            if (arr && arr.length > 0) {
                const index = this.getCltTypeRequiredMatrixIndex(this.getForm().cltType);
                if (index !== -1) {
                    return arr[index];
                }
            }
        }

        return false;
    }

    public getCltTypeRequiredMatrixIndex(cltType: string): number {

        const cltTypeOrder = [
            VmdConstants.CLT_TYPE_INDIVIDU,
            VmdConstants.CLT_TYPE_CONJOINT,
            VmdConstants.CLT_TYPE_ASSOCIATION,
            VmdConstants.CLT_TYPE_CLUB,
            VmdConstants.CLT_TYPE_COOPERATIVE,
            VmdConstants.CLT_TYPE_CORPORATION,
            VmdConstants.CLT_TYPE_CURATELLE,
            VmdConstants.CLT_TYPE_ENF_REF,
            VmdConstants.CLT_TYPE_ENF_ES_QUALITES,
            VmdConstants.CLT_TYPE_CO_UNIQUE,
            VmdConstants.CLT_TYPE_MANDATAIRE,
            VmdConstants.CLT_TYPE_FIDUCIE,
            VmdConstants.CLT_TYPE_ORG_GOUV,
            VmdConstants.CLT_TYPE_OSBL,
            VmdConstants.CLT_TYPE_SENC,
            VmdConstants.CLT_TYPE_SOC_COMM,
            VmdConstants.CLT_TYPE_SUCC,
            VmdConstants.CLT_TYPE_TUTELLE,
            VmdConstants.CLT_TYPE_RRI
        ];

        return cltTypeOrder.indexOf(cltType);
    }

    public getTotalFileSize() {
        let size = 0;
        if (this.getForm().transfer && this.getForm().accountTransferList.length) {
            for (const item of this.getForm().accountTransferList) {
                size += +item.accountStatement.size;
            }
        }
        if (this.getForm().rrspTransfer && this.getForm().rrspAccountTransferList.length) {
            for (const item of this.getForm().rrspAccountTransferList) {
                size += +item.accountStatement.size;
            }
        }
        if (this.getForm().tfsaTransfer && this.getForm().tfsaAccountTransferList.length) {
            for (const item of this.getForm().tfsaAccountTransferList) {
                size += +item.accountStatement.size;
            }
        }
        if (this.getForm().fhsaTransfer && this.getForm().fhsaAccountTransferList.length) {
            for (const item of this.getForm().fhsaAccountTransferList) {
                size += +item.accountStatement.size;
            }
        }
        if (this.getForm().liraTransfer && this.getForm().liraAccountTransferList.length) {
            for (const item of this.getForm().liraAccountTransferList) {
                size += +item.accountStatement.size;
            }
        }
        if (this.getForm().rrifTransfer && this.getForm().rrifAccountTransferList.length) {
            for (const item of this.getForm().rrifAccountTransferList) {
                size += +item.accountStatement.size;
            }
        }
        if (this.getForm().lifTransfer && this.getForm().lifAccountTransferList.length) {
            for (const item of this.getForm().lifAccountTransferList) {
                size += +item.accountStatement.size;
            }
        }
        return size;
    }


    public applyRules(path: string = null) {
        if (path === null || path === 'requesters.0.userAddressProv' || path === 'addRRSP') {
            this.navigationService.checkBeneficiaryRRSPActive(this.getForm().requesters[0].userAddressProv, this.getForm().addRRSP);
        }
        if (path === null || path === 'requesters.0.userAddressProv' || path === 'addTFSA') {
            this.navigationService.checkBeneficiaryTFSAActive(this.getForm().requesters[0].userAddressProv, this.getForm().addTFSA);
        }
        if (path === null || path === 'requesters.0.userAddressProv' || path === 'addFHSA' || path === 'requesters.0.userMaritalStatus') {
            this.navigationService.checkBeneficiaryFHSAActive(this.getForm().requesters[0].userAddressProv, this.getForm().requesters[0].userMaritalStatus, this.getForm().addFHSA);
        }
        if (path === null || path === 'addRESP') {
            this.navigationService.checkBeneficiaryRESPActive(this.getForm().requesters[0].userAddressProv, this.getForm().addRESP);
        }
        if (path === null || path === 'optionAct') {
            this.navigationService.checkOptionsActived(this.getForm().optionAct);
        }
        if (this.isTransmissionPath(path)) {
            this.navigationService.checkTransmissionPath(this.isEsignDisabled(),
                this.getForm().transmissionMethod,
                this.withTransmissionAdditionalForms(),
                this.isCaisseContext());
        }
        if (this.isTransferRequestPath(path)) {
            this.navigationService.checkTransferRequestNav(this.getForm());
        }
        if (path === null) {
            this.navigationService.checkDisnatAgent();
            this.navigationService.checkConfirmationPage(this.isCaisseContext());
        }
        if (path === null || path === 'jointActPeople') {
            this.navigationService.checkJointRelatedQuestionsNav(this.getForm());
        }
        if (path === null || path === 'cltType') {
            this.navigationService.checkAssociationRelatedQuestionsNav(this.getForm());
            this.navigationService.checkClubRelatedQuestionsNav(this.getForm());
            this.navigationService.checkCooperativeRelatedQuestionsNav(this.getForm());
            this.navigationService.checkCorporationRelatedQuestionsNav(this.getForm());
            this.navigationService.checkCoUniqueRelatedQuestionsNav(this.getForm());
            this.navigationService.checkEnfantEsQualitesRelatedQuestionsNav(this.getForm());
            this.navigationService.checkEnfantRefRelatedQuestionsNav(this.getForm());
            this.navigationService.checkFiducieRelatedQuestionsNav(this.getForm());
            this.navigationService.checkIppRelatedQuestionsNav(this.getForm());
            this.navigationService.checkJointRelatedQuestionsNav(this.getForm());
            this.navigationService.checkOsblRelatedQuestionsNav(this.getForm());
            this.navigationService.checkSencRelatedQuestionsNav(this.getForm());
            this.navigationService.checkSocCommRelatedQuestionsNav(this.getForm());
            this.navigationService.checkSuccRelatedQuestionsNav(this.getForm());
            this.navigationService.checkOrgGouvRelatedQuestionsNav(this.getForm());

            this.navigationService.checkPleinExOtherAccountPersonalInformationNav(this.getForm());
            this.navigationService.checkReferredAccountNav(this.getForm());
            this.navigationService.checkPleinExModeAutoNav(this.isPrintMode());
            this.navigationService.checkAttorneyOtherAccountPleinExNav(this.getForm());
            this.navigationService.checkCivilStatusPath(this.getForm());
            this.navigationService.checkOtherAccountSpecificConsentEligibility(this.getForm());
        }

        if (path === null || path === 'cltType' || path === 'otherFiducieType') {
            this.navigationService.checkEntityNav(this.getForm());
        }
        if (path === null || path === 'mgntType') {
            this.navigationService.checkLegalShareholderPath(this.getForm());

            if (!this.isEligibleToShowCommunicationBeneficialOwnersSection()) {
                this.setFormProperty('requesters.0.userDisclaimerIndicator', null);
                this.setFormProperty('requesters.0.userReceiptDocumentSecuritiesHolderIndicator', null);
                this.setFormProperty('requesters.0.userElectronicDocumentSecuritiesHolderIndicator', null);
                this.setFormProperty('requesters.0.legalShareHolderMsgWarning', null);
            }
        }
        if (path === null || path === 'cltType' || path === 'jointActPeople' || path === 'otherAddSpouse') {
            if (this.config.IS_PLEIN_EX && this.getForm().cltType === VmdConstants.CLT_TYPE_CONJOINT && this.getForm().jointActPeople === 3) {
                this.initRequesterList(3);
            } else if (this.config.IS_PLEIN_EX && this.getForm().cltType === VmdConstants.CLT_TYPE_CONJOINT && this.getForm().jointActPeople === 2) {
                this.initRequesterList(2);
            } else if (this.config.IS_PLEIN_EX && (this.getForm().cltType === VmdConstants.CLT_TYPE_ENF_REF || this.getForm().cltType === VmdConstants.CLT_TYPE_ENF_ES_QUALITES) && this.getForm().otherAddSpouse) {
                this.initRequesterList(2);
            } else {
                this.initRequesterList(1);
            }

            let shouldDiplayUpdatePrintTransmission = this.getForm().significantChange ||
                (this.getForm().requestUpdatePath === VmdConstants.PATH_IMPRESSION);
            this.displayUpdateTransmissionSubmitPath(shouldDiplayUpdatePrintTransmission);

            this.navigationService.checkPleinExOtherAddSpouseNav(this.getForm());
        }

        this.navigationService.checkIfTooglePdfIsActivated(this.config.TOGGLE_PDF_LANG);
    }

    displayUpdateTransmissionSubmitPath(isSignificantChange: boolean) {
        const isPilotMember = this.config.IS_UPDATE_PILOT_MEMBER;
        const isUpdateAccount = this.config.IS_UPDATE_ACCOUNT;

        if (isUpdateAccount && isPilotMember) {
            const isUpdateDateBeforeMax = this.isUpdateDateBeforeMaxDateOrNull();
            const isNotComparableSignificantInformation = this.notComparableSignificantInformationDetected(this.form.requesters[0]);
            const disable: boolean = this.form.mostRecentFormDate == null || isUpdateDateBeforeMax || isSignificantChange || isNotComparableSignificantInformation;

            this.navigationService.disableNavTransmissionPersonalAccountUpdatePath(!disable);
            this.navigationService.disableNavStepSubmitAccountUpdatePath(disable);
            this.navigationService.disableNavReviewUpdateAccountPath(false);
        } else {
            this.navigationService.disableNavTransmissionPersonalAccountUpdatePath(true);
            this.navigationService.disableNavStepSubmitAccountUpdatePath(true);
            this.navigationService.disableNavReviewUpdateAccountPath(true);
        }
    }

    public notComparableSignificantInformationDetected(requester: Requester) {
        const isNotComparableSignificantUserEmployerIsBroker = this.notComparableSignificantUserEmployerIsBroker(requester.userEmployerIsBroker);

        return isNotComparableSignificantUserEmployerIsBroker ||
            requester.userSameAddressWithBrokerEmployeeIndicator ||
            requester.userInitieIndicator ||
            requester.userMainShareholderIndicator ||
            requester.userPoliticallyExposedForeignPersonIndicator ||
            requester.userFinancialInterestIndicator ||
            requester.userOtherGuarantingPersonIndicator ||
            requester.userTrustedPersonIndicator ||
            this.form.borrowedForInvestmentPurposeIndicator;
    }

    public initRequesterList(length: number): void {

        if (!Array.isArray(this.getForm().requesters)) {
            this.getForm().requesters = [];
        }

        // add missing items
        while (this.getForm().requesters.length < length) {
            this.getForm().requesters.push(new Requester({}));
        }

        // remove excess items
        while (this.getForm().requesters.length > length) {
            this.getForm().requesters.pop();
        }
    }

    public isEsignDisabled(): boolean {

        return this.config.AGENT_DISNAT_USERNAME !== '' || this.getForm().requesters[0].userAddressProv === VmdConstants.MANITOBA;
    }

    isReadOnly(field): boolean {
        if (!this.isFromSmartD() && this.isCaisseContext()) {
            if (field === null || typeof field === 'undefined') {
                return false;
            } else {
                return true;
            }
        }

        return false;
    }

    public isFromSmartD(): boolean {

        return this.config.AGENT_DISNAT_USERNAME !== null && this.config.AGENT_DISNAT_USERNAME !== '';
    }

    public isCaisseContext(): boolean {
        return (null !== Branch.getInstance() && !this.isNullOrEmpty(Branch.getInstance().act) &&
                (Branch.getInstance().act.toUpperCase() === 'NEW' || Branch.getInstance().act.toUpperCase() === 'LIST')) ||
            (!this.isNullOrEmpty(this.config.SOURCE)) && this.config.SOURCE.toUpperCase() === 'CAISSE';
    }

    public isNullOrEmpty(value): boolean {
        return !value || !value.trim();
    }

    public initBranchParams(): void {
        const branch: Branch = Branch.getInstance();
        if (branch !== null) {
            if (!this.isNullOrEmpty(branch.act) && (branch.act.toUpperCase() === 'NEW' || branch.act.toUpperCase() === 'LIST')) {
                this.getForm().branch = branch;
            }
        }
    }

    public initAdvisorIdentityParams(): void {
        const branch: Branch = Branch.getInstance();
        if (branch !== null) {
            if (!this.isNullOrEmpty(branch.act) && (branch.act.toUpperCase() === 'NEW' || branch.act.toUpperCase() === 'LIST')) {

                const advisorIdentity = new AdvisorIdentity();

                advisorIdentity.prenom = branch.advFstNam;
                advisorIdentity.nom = branch.advLstNam;
                advisorIdentity.advisorCode = branch.codeUsager;
                advisorIdentity.langue = branch.langue;
                advisorIdentity.numeroInstitution = branch.instOrig;
                advisorIdentity.numeroTransit = branch.transitOrig;

                this.getForm().advisorIdentity = advisorIdentity;
            }
        }
    }

    private isTransferRequestPath(path: string): boolean {
        if (path === null) {
            return true;
        } else {
            const transferRequestPathList = this.config.TOGGLE_CELI_APP ? VmdConstants.TRANSFER_REQUEST_TYPES : VmdConstants.TRANSFER_REQUEST_TYPES_TOGGLE_CELIAPP_OFF;
            for (const item of transferRequestPathList) {
                if (path === item) {
                    return true;
                }
            }
        }
        return false;
    }

    private isTransmissionPath(path: string): boolean {
        return path === null
            || path === 'requesters.0.userAddressProv'
            || path === 'transmissionMethod'
            || path === 'addRESP'
            || path === 'addLIRA'
            || path === 'addRRIF'
            || path === 'addLIF'
            || path === 'requesters.0.userPoliticallyExposedForeignPersonIndicator'
            || path === 'requesters.0.userFinancialInterestIndicator'
            || path === 'beneficiaryReerActiveIndicator'
            || path === 'beneficiaryCeliActiveIndicator';
    }

    public withTransmissionAdditionalForms(): boolean {
        return this.getForm().addRESP
            || this.getForm().addLIRA
            || this.getForm().addRRIF
            || this.getForm().addLIF
            || this.getForm().requesters[0].userPoliticallyExposedForeignPersonIndicator
            || this.getForm().requesters[0].userFiscalRelationUsa
            || this.getForm().requesters[0].userFinancialInterestIndicator
            || this.getForm().beneficiaryReerActiveIndicator
            || this.getForm().beneficiaryCeliActiveIndicator
            || this.getForm().requesters[0].userOtherTradingPersonIndicator
            || this.getForm().requesters[0].userTrustedPersonIndicator;
    }

    public getAdditionalFormsList() {
        const formsList: string[] = [];
        if (this.getForm().addRESP) {
            // type
            if (this.getForm().respType === this.constants.RESP_TYPE_INDIVIDUAL) {
                formsList.push('ADDITIONAL_FORM_VD150');
            } else if (this.getForm().respType === this.constants.RESP_TYPE_FAMILY) {
                formsList.push('ADDITIONAL_FORM_VD150F');
            }
            // beneficiary
            formsList.push('ADDITIONAL_FORM_SDE0093');
            if (this.getForm().respNumberOfBeneficiaries > 1) {
                formsList.push('ADDITIONAL_FORM_SDE0093A');
            }
            // subscriber responsible
            if (!this.getForm().respIsSubscriberResponsible) {
                formsList.push('ADDITIONAL_FORM_SDE0093B');
            }

            // ------------transfer
            if (this.getForm().respTransfer) {
                formsList.push('ADDITIONAL_FORM_SDE0100A');
                if (this.getForm().requesters[0].userAddressProv === this.constants.QUEBEC) {
                    // • (x <Nombre de bénéficiaires>)
                    formsList.push('ADDITIONAL_FORM_TP1029_8_IQ');

                }
            }
        }
        if (this.getForm().addLIRA) {
            formsList.push('ADDITIONAL_FORM_VD117');
            if (this.getForm().liraJurisdiction === this.constants.ALBERTA) {
                formsList.push('ADDITIONAL_FORM_VD217AB');
            }
            if (this.getForm().liraJurisdiction === this.constants.BRITISH_COLUMBIA) {
                formsList.push('ADDITIONAL_FORM_VD117CB');
            }
            if (this.getForm().liraJurisdiction === this.constants.FEDERAL) {
                formsList.push('ADDITIONAL_FORM_VD117FED');
            }
            if (this.getForm().liraJurisdiction === this.constants.FEDERAL_RESTRICTED) {
                formsList.push('ADDITIONAL_FORM_VD117FEDR');
            }
            if (this.getForm().liraJurisdiction === this.constants.MANITOBA) {
                formsList.push('ADDITIONAL_FORM_VD117MB');
            }
            if (this.getForm().liraJurisdiction === this.constants.NEW_BRUNSWICK) {
                formsList.push('ADDITIONAL_FORM_VD117NB');
            }
            if (this.getForm().liraJurisdiction === this.constants.NOVA_SCOTIA) {
                formsList.push('ADDITIONAL_FORM_VD217NS');
            }
            if (this.getForm().liraJurisdiction === this.constants.ONTARIO) {
                formsList.push('ADDITIONAL_FORM_VD117ONT');
            }
            if (this.getForm().liraJurisdiction === this.constants.QUEBEC) {
                formsList.push('ADDITIONAL_FORM_VD117QC');
            }
        }
        if (this.getForm().addRRIF) {
            formsList.push('ADDITIONAL_FORM_RRIF_VD155');
        }
        if (this.getForm().addLIF) {
            formsList.push('ADDITIONAL_FORM_LIF_VD155');
            // province
            if (this.getForm().lifJurisdiction === this.constants.ALBERTA) {
                formsList.push('ADDITIONAL_FORM_VD255AB');
                if (this.getForm().requesters[0].userMaritalStatus === this.constants.MARITAL_STATUS_MARRIED || this.getForm().requesters[0].userMaritalStatus === this.constants.MARITAL_STATUS_LIVING_COMMON_LAW) {
                    formsList.push('ADDITIONAL_FORM_ABFORM10');
                }
            }
            if (this.getForm().lifJurisdiction === this.constants.BRITISH_COLUMBIA) {
                formsList.push('ADDITIONAL_FORM_VD255CB');
                if (this.getForm().requesters[0].userMaritalStatus === this.constants.MARITAL_STATUS_MARRIED || this.getForm().requesters[0].userMaritalStatus === this.constants.MARITAL_STATUS_LIVING_COMMON_LAW) {
                    formsList.push('ADDITIONAL_FORM_BCFORM3');
                }
            }
            if (this.getForm().lifJurisdiction === this.constants.FEDERAL) {
                formsList.push('ADDITIONAL_FORM_VD155FED');
            }
            if (this.getForm().lifJurisdiction === this.constants.FEDERAL_RESTRICTED) {
                formsList.push('ADDITIONAL_FORM_VD255FEDR');
            }
            if (this.getForm().lifJurisdiction === this.constants.MANITOBA) {
                formsList.push('ADDITIONAL_FORM_VD155MB');
            }
            if (this.getForm().lifJurisdiction === this.constants.NEW_BRUNSWICK) {
                formsList.push('ADDITIONAL_FORM_VD155NB');
            }
            if (this.getForm().lifJurisdiction === this.constants.NOVA_SCOTIA) {
                formsList.push('ADDITIONAL_FORM_VD255NS');
                if (this.getForm().requesters[0].userMaritalStatus === this.constants.MARITAL_STATUS_MARRIED || this.getForm().requesters[0].userMaritalStatus === this.constants.MARITAL_STATUS_LIVING_COMMON_LAW) {
                    formsList.push('ADDITIONAL_FORM_FORM9');
                }
            }
            if (this.getForm().lifJurisdiction === this.constants.ONTARIO) {
                formsList.push('ADDITIONAL_FORM_VD155ONTN');
            }
            if (this.getForm().lifJurisdiction === this.constants.QUEBEC) {
                formsList.push('ADDITIONAL_FORM_VD155QC');
            }

        }
        if (this.getForm().requesters[0].userPoliticallyExposedForeignPersonIndicator) {
            formsList.push('ADDITIONAL_FORM_CF00002027');
        }
        if (this.getForm().requesters[0].userFiscalRelationUsa) {
            formsList.push('ADDITIONAL_FORM_VD170');
        }
        if (this.getForm().requesters[0].userFinancialInterestIndicator) {
            formsList.push('ADDITIONAL_FORM_VD1051');
        }


        if (this.getForm().beneficiaryReerActiveIndicator) {
            formsList.push('ADDITIONAL_FORM_VD1061_REER');
        }
        if (this.getForm().beneficiaryCeliActiveIndicator) {
            formsList.push('ADDITIONAL_FORM_VD1061_CELI');
        }

        if (this.getForm().requesters[0].userOtherTradingPersonIndicator) {
            formsList.push('ADDITIONAL_FORM_VD104');
        }

        if (this.getForm().requesters[0].userTrustedPersonIndicator) {
            formsList.push('ADDITIONAL_FORM_VD104A');
        }
        return formsList;
    }

    public setFormProperty(path, value): void {
        if (path != null && path !== '') {
            this.setObjectProperty(this.form, path, value);
            this.applyRules(path);
            this.notifyChange(path);
            this.refreshSession();

            if (!environment.production && path !== null) {
                console.log('ODCForm update: ', path, JSON.stringify(value));
            }
        }
    }

    public getForm(): ODCForm {

        // Clean account transfer requests here to remove empty ones

        return this.form;
    }

    private notifyChange(path): void {

        if (path === 'userEmail') {

            this.subjectChange.next(this.form);
        }
    }

    public getEsignUrl(): Observable<any> {
        return this.http.get('api/esign/url', {withCredentials: true});
    }

    public submitEsignCaisse(): Observable<any> {
        return this.http.get('api/esign/caisse/submit', {withCredentials: true});
    }

    public isEsignInProgress(): Observable<any> {
        return this.http.get('api/esign/progress', {withCredentials: true});
    }

    public saveForm(): Observable<any> {
        return this.submitForm('api/save');
    }

    public completeForm(): Observable<any> {
        if (this.config.IS_UPDATE_ACCOUNT && this.config.IS_UPDATE_PILOT_MEMBER) {
            this.form.requestUpdatePath = VmdConstants.PATH_SOUMISSION;
        }
        return this.submitForm('api/complete');
    }

    public printForm(): Observable<any> {
        if (this.config.IS_UPDATE_ACCOUNT && this.config.IS_UPDATE_PILOT_MEMBER) {
            this.form.requestUpdatePath = VmdConstants.PATH_IMPRESSION;
        }
        return this.submitForm('api/print');
    }

    public compareSavedForm(): Observable<any> {

        return this.submitFormFormComparison('api/compare-saved-form', this.config.ENCRYPTED_URL);
    }

    public transmitForm(): Observable<any> {

        return this.http.post('api/transmit', this.getTransmissionJSONValue(), {withCredentials: true});
    }

    public saveCompletedForm(): Observable<any> {
        return this.http.post('api/save-completed', this.getTransmissionJSONValue(), {withCredentials: true});
    }

    public saveTransmittedForm(): Observable<any> {

        return this.http.post('api/save-transmitted', this.getTransmissionJSONValue(), {withCredentials: true});
    }

    private submitForm(path: string): Observable<any> {
        this.alterFormBeforeSubmit();
        return this.http.post(path, this.getJSONValue(), {withCredentials: true});
    }

    private submitFormFormComparison(path: string, encryptedUrl): Observable<any> {
        this.alterFormBeforeSubmit();
        return this.http.post(path, this.getJSONValue(), {withCredentials: true, params: {encryptedUrl}});
    }

    public emailAdditionForm(path: string): Observable<any> {
        this.alterFormBeforeSubmit();
        return this.http.get(path);
    }

    public isConvertedRequest(): boolean {
        const conversionIndicator = this.getForm().conversionIndicator;
        return this.config.IS_PLEIN_EX && VmdConstants.REQUEST_CONVERTED === conversionIndicator;
    }

    public isEditableConvertedRequest(): boolean {
        const requestStatus = this.getForm().requestStatus;
        return this.isConvertedRequest() &&
            (requestStatus === VmdConstants.REQUEST_STATUS_INJECTED || requestStatus === VmdConstants.REQUEST_STATUS_REJECTED);
    }

    private refreshSession() {

        if (this.sessionService.hasSessionInProgress()) {

            this.sessionService.refreshSession().subscribe((data: any) => {

                // Nothing to do here for now
            });
        }
    }

    private alterFormBeforeSubmit(): void {

        this.form.lang = this.translate.currentLang;

        this.upperCaseZipCode();

        // OCE-14644: When ShareholderBeneficialOwners questions are hidden, values default to map to communicationBeneficialOwnersType of CTR_TYP = 12
        if (!this.isEligibleToShowCommunicationBeneficialOwnersSection() && !this.getForm().portfolioManagementMandateOnly) {
            this.setFormProperty('requesters.0.userDisclaimerIndicator', true);
            this.setFormProperty('requesters.0.userReceiptDocumentSecuritiesHolderIndicator', 'ALL');
            this.setFormProperty('requesters.0.userElectronicDocumentSecuritiesHolderIndicator', false);
        }
    }

    private upperCaseZipCode(): void {
        // requester
        this.upperCaseZipCodeOnList('requesters', 'userAddressPostalCode');
        this.upperCaseZipCodeOnList('requesters', 'userAddressPostalCodeSec');

        this.upperCaseZipCodeOnProperty('userEmployerAddressPostalCode');
        this.upperCaseZipCodeOnProperty('beneficiaryReerAdressPostalCode');
        this.upperCaseZipCodeOnProperty('beneficiaryReerAdressPostalCodeSec');
        this.upperCaseZipCodeOnProperty('beneficiaryReerAdressPostalCodeTer');
        this.upperCaseZipCodeOnProperty('beneficiaryCeliAdressPostalCode');
        this.upperCaseZipCodeOnProperty('beneficiaryCeliAdressPostalCodeSec');
        this.upperCaseZipCodeOnProperty('beneficiaryCeliAdressPostalCodeTer');
        this.upperCaseZipCodeOnProperty('attorneyAddressPostalCode');

        // transfer
        this.upperCaseZipCodeOnList('accountTransferList', 'addressPostalCode');
        this.upperCaseZipCodeOnList('rrspAccountTransferList', 'addressPostalCode');
        this.upperCaseZipCodeOnList('tfsaAccountTransferList', 'addressPostalCode');
        this.upperCaseZipCodeOnList('fhsaAccountTransferList', 'addressPostalCode');
        this.upperCaseZipCodeOnList('respAccountTransferList', 'addressPostalCode');
        this.upperCaseZipCodeOnList('liraAccountTransferList', 'addressPostalCode');
        this.upperCaseZipCodeOnList('rrifAccountTransferList', 'addressPostalCode');
        this.upperCaseZipCodeOnList('lifAccountTransferList', 'addressPostalCode');
    }

    private upperCaseZipCodeOnProperty(property: string): void {
        if (this.form[property]) {
            this.form[property] = this.form[property].toUpperCase();
        }
    }

    private upperCaseZipCodeOnList(list: string, property: string): void {
        if (this.form[list] && this.form[list].length > 0) {
            for (const item of this.form[list]) {
                if (item[property]) {
                    item[property] = item[property].toUpperCase();
                }
            }
        }
    }

    private getJSONValue(): string {
        return this.getJSONValueFromOjbect(this.form);
    }

    private getTransmissionJSONValue(): string {
        return this.getJSONValueFromOjbect({
            lang: this.translate.currentLang,
            transmissionMethod: this.form.transmissionMethod,
            userIdentityCode: this.form.requesters[0].userIdentityCode,
            userIdentityFile: this.form.userIdentityFile,
            userSpecimenFile: this.form.userSpecimenFile,
            userAllFiles: this.form.userAllFiles,
            userIdentityFiles: this.form.userIdentityFiles,
            esignUserPhone: this.form.esignUserPhone,
            userOtherTradingPersonIndicator: this.form.requesters[0].userOtherTradingPersonIndicator,
            userFiscalRelationUsa: this.form.requesters[0].userFiscalRelationUsa,
            userFinancialInterestIndicator: this.form.requesters[0].userFinancialInterestIndicator,
            userPoliticallyExposedForeignPersonIndicator: this.form.requesters[0].userPoliticallyExposedForeignPersonIndicator,
            userTrustedPersonIndicator: this.form.requesters[0].userTrustedPersonIndicator,
            beneficiaryReerActiveIndicator: this.form.beneficiaryReerActiveIndicator,
            beneficiaryCeliActiveIndicator: this.form.beneficiaryCeliActiveIndicator,
            addRESP: this.form.addRESP,
            addLIRA: this.form.addLIRA,
            addRRIF: this.form.addRRIF,
            addLIF: this.form.addLIF,
            resQc: this.form.resQc,
            caisseContext: this.isCaisseContext(),
            userIdentities: this.form.requesters[0].userIdentities
        });
    }

    private getJSONValueFromOjbect(object: any): string {

        const json: string = JSON.stringify(object);

        return json.replace(/:(\d+)([,\}])/g, ':"$1"$2');
    }

    private setObjectProperty(object, path, value): void {

        const propertiesStack = path.split('.');

        while (typeof object !== 'undefined' && propertiesStack.length > 1) {

            object = object[propertiesStack.shift()];
        }

        const lastProperty = propertiesStack.shift();

        if (typeof object !== 'undefined') {

            if (object[lastProperty] instanceof Array) {

                object[lastProperty].push(value);
            } else {

                object[lastProperty] = value;
            }

        }
    }

    public getOssQueryTfsaCurrency(): string {
        return this.ossQueryTfsaCurrency;
    }

    public isAddAccountPleinExPath(): boolean {
        return this.constants.ACCOUNT_ADD_ACCOUNT === this.getForm().newExistAct &&
            !Functions.isNullOrEmpty(this.getForm().cltNo) &&
            this.getCurrentComposant().isPleinEx();
    }

    public isRecoveredRequest(): boolean {
        return !!this.config.FROM_EXISTING_REQUEST;
    }

    public hasAnnuitantSpouseAccount(): boolean {
        return (
                this.getForm().addRRSP
                && this.getForm().rrspAnnuitant === this.constants.ANNUITANT_SPOUSE
            )
            || (
                this.getForm().addRRSPSec
                && this.getForm().rrspAnnuitantSec === this.constants.ANNUITANT_SPOUSE
            )
            || (
                this.getForm().addRRSPTer
                && this.getForm().rrspAnnuitantTer === this.constants.ANNUITANT_SPOUSE
            )
            || (
                this.getForm().addRRIF
                && this.getForm().rrifAnnuitant === this.constants.ANNUITANT_SPOUSE
            )
            || (
                this.getForm().addRRIFSec
                && this.getForm().rrifAnnuitantSec === this.constants.ANNUITANT_SPOUSE
            )
            || (
                this.getForm().addFTARRSP
                && this.getForm().ftarrspAnnuitant === this.constants.ANNUITANT_SPOUSE
            )
            || (
                this.getForm().addFTARRSPSec
                && this.getForm().ftarrspAnnuitantSec === this.constants.ANNUITANT_SPOUSE
            )
            || (
                this.getForm().addFTARRSPTer
                && this.getForm().ftarrspAnnuitantTer === this.constants.ANNUITANT_SPOUSE
            );
    }

    public isEligibleToShowCommunicationBeneficialOwnersSection(): boolean {
        const odcForm = this.getForm();
        return !(odcForm.gpd || odcForm.sfd || odcForm.portfolioManagementMandateOnly);
    }

    addressFormTypeIsNotSelectedOnUpdateAccountContext(): boolean {
        const sessionAddressType = sessionStorage.getItem(this.constants.OSS_ADDRESS_TYPE_SESSION_KEY);

        return this.config.IS_UPDATE_ACCOUNT && (!this.config.FROM_EXISTING_REQUEST || this.config.FROM_EXISTING_REQUEST === '')
            && sessionAddressType !== this.constants.OSS_HOME_ADDRESS && sessionAddressType !== this.constants.OSS_MAILING_ADDRESS;
    }

    public getSessionUserName(): Observable<any> {
        return this.http.get('api/session/user-name');
    }

    isFormLastUpdateDateWasBeforeDefinedMaxDate() {

        if (this.form.mostRecentFormDate == null) {
            return true;
        }

        const updateDate = new Date(this.form.mostRecentFormDate);
        const maxUpdateDate = new Date(VmdConstants.LAST_UPDATE_DATE_MAX);
        return updateDate && updateDate < maxUpdateDate;
    }

    isUpdateDateBeforeMaxDateOrNull() {
        return this.isFormLastUpdateDateWasBeforeDefinedMaxDate() || !this.form.mostRecentFormDate;
    }

    clientIsResidentOfQuebec(): boolean {
        return (this.getForm().requesters[0].userAddressProv ===
            VmdConstants.QUEBEC && this.isOtherAccountCuratelle());
    }

    isClientTypeOtherAccount(): boolean {
        return !(this.getForm().cltType === this.constants.CLT_TYPE_INDIVIDU ||
            this.getForm().cltType === this.constants.CLT_TYPE_CONJOINT);
    }

    isOtherAccountCuratelle(): boolean {
        return this.getForm().cltType === this.constants.CLT_TYPE_CURATELLE;
    }

    isQuebecResident(): boolean {

        const isQcProv = this.form.requesters[0].userAddressProv === VmdConstants.QUEBEC;

        if (this.config.IS_PLEIN_EX && (this.form.cltType === VmdConstants.CLT_TYPE_CONJOINT || this.isClientTypeOtherAccount())) {
            return isQcProv;
        }

        return this.form.cltType === VmdConstants.CLT_TYPE_INDIVIDU ? isQcProv : this.form.resQc;
    }

    isClientEnglishSpeaking(): boolean {
        return (this.config.IS_PLEIN_EX || this.config.IS_UPDATE_ACCOUNT) ? this.form.requesters[0].formLang === VmdConstants.LANG_EN
            : this.translate.currentLang === VmdConstants.LANG_EN;
    }

    private notComparableSignificantUserEmployerIsBroker(userEmployerIsBroker: string) {
        return userEmployerIsBroker === VmdConstants.USER_EMPLOYER_IS_BROKER_OTHER_BROKER &&
            this.jobInfosHaveChanged();
    }

    private jobInfosHaveChanged() {
        return this.formsComparisonService.getComparisonResults()
            .filter(e => e.fieldName === VmdConstants.USER_EMPLOYER_NAME_FIELDNAME
                || e.fieldName === VmdConstants.USER_JOBTITLE_FIELDNAME)
            .length > 0;
    }
}
