import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {AttachedDocument} from '../../../../../components/models';
import {FormService} from '../../../../../components/services/form.service';
import {AttachedDocumentService} from '../../../../../components/services/attached-document.service';
import {VmdForm} from '../../../../../components/utils/vmd-form';
import {TranslateService} from '@ngx-translate/core';
import {VmdConstants} from '../../../../../components/constants/vmd-constants';
import ordinal from 'ordinal';
import {VmdValidators} from '../../../../../components/validators/vmd-validators';

declare let $: any;

const ATTACHED_DOCUMENT_IDENTITY_PIECE_NAME = 'userIdentityFile';
const ATTACHED_DOCUMENT_IDENTITY_BACK_PIECE_NAME = 'userIdentityFileBack';

@Component({
    selector: 'app-input-file-field',
    templateUrl: './input-file-field.component.html',
    styleUrls: ['./input-file-field.component.scss']
})
export class InputFileFieldComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() index: number;
    @Input() label: string;
    @Input() labelDocument: string;
    @Input() labelDocumentBack: string;
    @Input() showLabel: boolean = true;
    @Input() isRequestSaving: boolean;
    @Input() hasBack: boolean;
    @Input() attrDisabled: boolean;

    @Input() path: string;
    @Input() submitted: boolean;
    @Input() errorOnSubmitOnly: string[] = null;

    documentOrdinal: string;
    defaultDocumentLabel: string;
    firstOrdinalEN: string;
    secondOrdinalEN: string;
    public constants = VmdConstants;

    constructor(private formService: FormService,
                protected fb: FormBuilder,
                private attachedDocumentService: AttachedDocumentService,
                public translate: TranslateService) {
    }

    ngOnInit(): void {
        this.documentOrdinal = this.getOrdinal(this.index + 1);
        this.firstOrdinalEN = ordinal(this.index + 1);
        this.secondOrdinalEN = ordinal(this.index + 1);

        this.initAttachedDocumentForm();

        this.form.get('attachedDocuments.' + this.index + '.attachedDocumentDifferentAddress').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('attachedDocuments.' + this.index + '.attachedDocumentBack'), value);

            this.defaultDocumentLabel = this.getDefaultDocumentLabel(value);

            if (!value) {
                this.removeFile(this.index, true);
            }
        });
        this.updateControlEnabled(this.form.get('attachedDocuments.' + this.index + '.attachedDocumentBack'), this.form.get('attachedDocuments.' + this.index + '.attachedDocumentDifferentAddress').value);

        this.defaultDocumentLabel = this.getDefaultDocumentLabel(this.form.get('attachedDocuments.' + this.index + '.attachedDocumentDifferentAddress').value);

        this.attachedDocumentService.loadedEmitted.subscribe(() => {
            this.mergeAttachedFiles();
        });
    }

    private getDefaultDocumentLabel(documentHasBack: boolean): string {
        return this.defaultDocumentLabel = this.isCustomizedLabel() ?
            this.getCustomizedLabel(documentHasBack):this.getGenericLabel(documentHasBack);
    }

    private isCustomizedLabel(): boolean {
        return this.labelDocument!=null;
    }

    private getGenericLabel(documentHasBack: boolean): string {
        if (this.label) {
            return;
        }
        return this.defaultDocumentLabel = documentHasBack ?
            'TRANSMISSION_ATTACHED_DOCUMENT_FRONT_LABEL' : 'TRANSMISSION_ATTACHED_DOCUMENT_LABEL';
    }

    private getCustomizedLabel(documentHasBack: boolean): string {
        return this.defaultDocumentLabel = documentHasBack ?
            this.labelDocument : this.label;
    }

    private getOrdinal(index: number): string {
        return this.translate.currentLang === this.constants.LANG_EN ? ordinal(index) : (index).toString();
    }

    private initAttachedDocumentForm(): void {

        const attachedDocumentFront = (this.formService.getForm().userIdentityFiles[this.index] == null ||
            this.formService.getForm().userIdentityFiles[this.index][0] == null) ?
            new AttachedDocument() : this.formService.getForm().userIdentityFiles[this.index][0];

        const attachedDocumentBack = (this.formService.getForm().userIdentityFiles[this.index] == null ||
            this.formService.getForm().userIdentityFiles[this.index][1] == null) ?
            new AttachedDocument() : this.formService.getForm().userIdentityFiles[this.index][1];

        const validators = this.getAttachedDocumentValidators();

        const attachedDocumentGroup = this.fb.group({
            attachedDocument: [attachedDocumentFront.name, validators],
            attachedDocumentContent: [attachedDocumentFront.content],
            attachedDocumentFile: [null],
            attachedDocumentForm: [attachedDocumentFront],
            attachedDocumentName: [ATTACHED_DOCUMENT_IDENTITY_PIECE_NAME + this.index, Validators.required],

            attachedDocumentBack: [attachedDocumentBack.name, validators],
            attachedDocumentContentBack: [attachedDocumentBack.content],
            attachedDocumentFileBack: [null],
            attachedDocumentFormBack: [attachedDocumentBack],
            attachedDocumentNameBack: [ATTACHED_DOCUMENT_IDENTITY_BACK_PIECE_NAME + this.index, Validators.required],

            attachedDocumentDifferentAddress: [attachedDocumentBack.content !== null]
        });

        this.getAttachedDocumentsControl().push(attachedDocumentGroup);
    }

    private mergeAttachedFiles() {
        this.getAttachedDocumentsControl().controls.forEach((control, index) => {
            const odcForm = this.formService.getForm();
            if (!odcForm.userIdentityFiles[this.index]) {
                odcForm.userIdentityFiles.push([new AttachedDocument({}), new AttachedDocument({})]);
            }

            let name = control.get('attachedDocumentName').value;
            if (name === ATTACHED_DOCUMENT_IDENTITY_PIECE_NAME + this.index) {
                odcForm.userIdentityFiles[this.index][0] = control.get('attachedDocumentForm').value;
                odcForm.userIdentityFiles[this.index][0].type = ATTACHED_DOCUMENT_IDENTITY_PIECE_NAME;
            }

            name = control.get('attachedDocumentNameBack').value;
            if (name === ATTACHED_DOCUMENT_IDENTITY_BACK_PIECE_NAME + this.index) {
                odcForm.userIdentityFiles[this.index][1] = control.get('attachedDocumentFormBack').value;
                odcForm.userIdentityFiles[this.index][1].type = ATTACHED_DOCUMENT_IDENTITY_BACK_PIECE_NAME;
            }
        });
    }

    getAttachedDocumentsControl(): FormArray {
        return this.form.get('attachedDocuments') as FormArray;
    }

    private getAttachedDocumentValidators(): ValidatorFn[] {
        return [Validators.required, VmdValidators.fileFormatValidator(['pdf', 'jpeg', 'jpg', 'png'])];
    }

    selectFile(name: string) {
        $('input[name="' + name + '"],select[name="' + name + '"]').first().click();
        return false;
    }

    removeFile(index: number, isBackSide: boolean = false) {

        if (isBackSide && this.formService.getForm().userIdentityFiles[this.index] != null &&
            this.formService.getForm().userIdentityFiles[this.index][1] != null) {
            this.formService.getForm().userIdentityFiles[index][1] = new AttachedDocument();
            this.clearControlsBackSide(this.form.get('attachedDocuments.' + index));
        } else if(this.formService.getForm().userIdentityFiles[this.index] != null &&
            this.formService.getForm().userIdentityFiles[this.index][0] != null){
            this.formService.getForm().userIdentityFiles[index][0] = new AttachedDocument();
            this.clearControls(this.form.get('attachedDocuments.' + index));
        }
    }

    onFileChange(event, index: number, isBackSide: boolean = false): void {

        if (isBackSide) {
            this.attachedDocumentService.onFileChange(
                event,
                this.form.get('attachedDocuments.' + index + '.attachedDocumentFormBack').value as AttachedDocument,
                this.form.get('attachedDocuments.' + index + '.attachedDocumentBack'),
                this.form.get('attachedDocuments.' + index + '.attachedDocumentContentBack')
            );
        } else {
            this.attachedDocumentService.onFileChange(
                event,
                this.form.get('attachedDocuments.' + index + '.attachedDocumentForm').value as AttachedDocument,
                this.form.get('attachedDocuments.' + index + '.attachedDocument'),
                this.form.get('attachedDocuments.' + index + '.attachedDocumentContent')
            );
        }
    }

    private clearControls(control: AbstractControl) {

        control.get('attachedDocument').setValue(null);
        control.get('attachedDocumentContent').setValue(null);
        control.get('attachedDocumentFile').setValue(null);

        control.get('attachedDocumentForm').setValue(new AttachedDocument());
    }

    private clearControlsBackSide(control: AbstractControl) {

        control.get('attachedDocumentBack').setValue(null);
        control.get('attachedDocumentContentBack').setValue(null);
        control.get('attachedDocumentFileBack').setValue(null);

        control.get('attachedDocumentFormBack').setValue(new AttachedDocument());
    }

    isFieldInError(controlName: string): boolean {
        return VmdForm.isFieldInError(this.form.get(this.path + '.' + controlName), this.submitted, this.errorOnSubmitOnly);
    }

    updateControlEnabled(control: AbstractControl, enabledCondition: boolean): void {
        if (enabledCondition) {
            if (control.disabled) {
                control.enable();
                control.markAsUntouched();
            }
        } else {
            if (control.enabled) {
                control.disable();

                // fix bug - sometimes, it is still enabled. Do it again
                if (control.enabled) {
                    control.disable();
                }
            }
        }
    }
}
