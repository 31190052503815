import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {FormService} from '../../components/services/form.service';
import {FormBuilder} from '@angular/forms';
import {NavigationService} from '../../components/services/navigation.service';
import {Router} from '@angular/router';
import {OdcConfig} from '../../odc-config';
import {I18N_NAV_MESSAGES_FR} from '../../components/i18n/nav-messages_fr';

@Component({
    selector: 'app-default-begin',
    templateUrl: './default-begin.component.html'
})
export class DefaultBeginComponent extends BaseNavComponent implements OnInit {

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private router: Router,
                private translatePipe: TranslatePipe
    ) {
        super(navigationService, fb, formService, translate);
    }

    goToLogin(): void {
        this.router.navigate(['/', this.translatePipe.transform('ODC_NAV_LOGIN_PATH')]);
    }

    ngOnInit(): void {
    }
}
