<aside *ngIf="displayAside()" class="panel panel-default" id="sidebar">

    <div class="panel-body">
        <div *ngIf="displaySaveBlock()" [class.m-b-5]="isNotPleinEx() && !isUpdateAccountContext()">
            <div class="save-actions">

                <div *ngIf="!saved">
                    <h2>{{ 'ODC_QUESTIONNAIRE_SAVE_TITLE' | translate }}</h2>
                    <p class="text-justify">{{ 'ODC_QUESTIONNAIRE_SAVE_MESSAGE' | translate }}</p>
                </div>

                <div *ngIf="saved">
                    <h2>
                        <span
                            *ngIf="!fromLogin && isPleinEx()">{{ 'ODC_QUESTIONNAIRE_SAVE_TITLE_SAVED_PLEINEX' | translate }}</span>
                        <span
                            *ngIf="!fromLogin && isNotPleinEx()">{{ 'ODC_QUESTIONNAIRE_SAVE_TITLE_SAVED' | translate }}</span>
                        <span *ngIf="fromLogin">{{ 'ODC_QUESTIONNAIRE_SAVE_TITLE_SAVED_REPRISE' | translate }}</span>
                    </h2>
                    <div *ngIf="isNotPleinEx()">
                        <p class="text-justify">
                            <span
                                *ngIf="!fromLogin && !isUpdateAccountContext()">{{ 'ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_1' | translate }}</span>
                            <span
                                *ngIf="!fromLogin && isUpdateAccountContext()">{{ 'ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_1_UPDATE' | translate }}</span>
                            <span *ngIf="fromLogin">{{ 'ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_1_REPRISE' | translate }}&nbsp;</span>
                            <a *ngIf="!isUpdateAccountContext()" class="lien-externe"
                               href="{{ 'ODC_QUESTIONNAIRE_SAVE_URL' | translate }}" target="_blank">
                                {{ 'ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_5' | translate }}
                                <span class="sr-only">{{ 'ODC_LINK_NEW_WINDOW' | translate }}</span>
                            </a>
                        </p>
                    </div>

                    <div *ngIf="isPleinEx()">
                        <p class="text-justify">
                            {{ 'ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_PLEINEX' | translate }}
                        </p>
                    </div>
                </div>

                <div>
                    <button (click)="saveOnClick()"
                            [attr.disabled]="(loading || isRequestInProgressOrSaving() || addressFormTypeIsNotSelectedOnUpdateAccountContext() || isSaveDisabled()) ? true : null"
                            [class.loading]="loading || isRequestInProgressOrSaving()"
                            class="btn btn-primary btn-block loadable"
                            name='saveBtn'>
                        {{ 'ODC_QUESTIONNAIRE_SAVE_BUTTON' | translate }}
                    </button>
                </div>

                <div *ngIf="saveError !== null" class="alert alert-danger">{{saveError | translate}}</div>

                <div *ngIf="displayPrintBlock()">
                    <div class="m-t-2">
                        <button (click)="print()"
                                [attr.disabled]="(loadingPrint || isSaving || addressFormTypeIsNotSelectedOnUpdateAccountContext() || isSaveDisabled()) ? true : null"
                                [class.loading]="loadingPrint"
                                class="btn btn-primary btn-block loadable"
                                name='printBtn'>
                            {{ 'ODC_QUESTIONNAIRE_PRINT_BUTTON' | translate }}
                        </button>
                    </div>

                    <div *ngIf="printError !== null" class="alert alert-danger">{{printError | translate}}</div>
                </div>

            </div>
        </div>

        <div *ngIf="displayAssistanceBlock()">
            <h2 class="contactus p-l-4">
                {{ 'SIDEBAR_ASSISTANCE_TITLE' | translate }}
            </h2>
            <div class="h1" itemscope itemtype="http://schema.org/BankOrCreditUnion">
                <span itemprop="telephone">
                    <a class="lien-sans-soulignement" href="tel:{{ 'SIDEBAR_ASSISTANCE_PHONE_LINK' | translate }}">
                        {{ 'SIDEBAR_ASSISTANCE_PHONE' | translate }}
                        <span class="sr-only">{{ 'SR_ONLY_PHONE' | translate }}</span>
                    </a>
                </span>
            </div>
            <div>
                {{ 'SIDEBAR_ASSISTANCE_SCHEDULE' | translate }}
            </div>
        </div>

        <app-aside-consult-all *ngIf="displayConsultAllBlock()"></app-aside-consult-all>
        <app-aside-confirmation *ngIf="displayConfirmationBlock()"></app-aside-confirmation>
    </div>
</aside>
