<div [formGroup]="formGroup" class="transfer-request">

    <h3>{{'TRANSFER_FORM_BANK_TITLE'|translate}}</h3>
    <fieldset>
        <legend class="sr-only">{{'TRANSFER_FORM_BANK_TITLE'|translate}}</legend>

        <div [class.error]="isFieldInError(getFormPath()+actTypeCurrency)">

            <app-error [form]="form" [ngFormRef]="ngFormRef" [path]="getFormPath()+actTypeCurrency"
                       [nameSuffix]="index"></app-error>

            <div class="clearfix">
                <div class="h4" [class.has-error]="isFieldInError(getFormPath()+actTypeCurrency)">
                    <span class="pull-left" tabindex="0">{{accountTitle|translate}}</span>
                    <div class="pull-left m-l-3 form-condensed">
                        <app-odc-field
                            [type]="constants.FIELD_TYPES.SELECT"
                            [form]="form.get('transferGroup.' + index)"
                            [submitted]="isNgFormSubmitted()"
                            [label]="'TRANSFER_FORM_ACCOUNT_TYPE' | translate"
                            [labelSrOnly]="true"
                            [id]="actTypeCurrency + index"
                            [name]="actTypeCurrency + '_' + index"
                            [controlName]="actTypeCurrency"
                            [path]="actTypeCurrency"
                            [nameSuffix]="index"
                            [binding]="getBindPath() + actTypeCurrency"
                            [list]="[{itemValue: constants.CURRENCY_CAD, itemLabel: 'CURRENCY_CAD'|translate}, {itemValue: constants.CURRENCY_USD, itemLabel: 'CURRENCY_USD'|translate}]"
                            [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                            [attrDisabled]="isActTypeCurrencyDisabled"
                        ></app-odc-field>
                    </div>
                </div>
            </div>
        </div>

        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form.get('transferGroup.' + index)"
            [submitted]="isNgFormSubmitted()"
            [label]="'TRANSFER_FORM_BANK_NAME' | translate"
            [id]="bankName + index"
            [name]="bankName + '_' + index"
            [controlName]="bankName"
            [path]="bankName"
            [binding]="getBindPath() + bankName"
            [attr]="{maxlength: 32}"
        ></app-odc-field>

        <div class="row">
            <div class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('transferGroup.' + index)"
                    [submitted]="isNgFormSubmitted()"
                    [label]="'TRANSFER_FORM_BANK_RES_FIRST_NAME' | translate"
                    [id]="bankResourceFirstName + index"
                    [name]="bankResourceFirstName + '_' + index"
                    [controlName]="bankResourceFirstName"
                    [path]="bankResourceFirstName"
                    [binding]="getBindPath() + bankResourceFirstName"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>
            </div>
            <div class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('transferGroup.' + index)"
                    [submitted]="isNgFormSubmitted()"
                    [label]="'TRANSFER_FORM_BANK_RES_LAST_NAME' | translate"
                    [id]="bankResourceLastName + index"
                    [name]="bankResourceLastName + '_' + index"
                    [controlName]="bankResourceLastName"
                    [path]="bankResourceLastName"
                    [binding]="getBindPath() + bankResourceLastName"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>
            </div>
        </div>

        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form.get('transferGroup.' + index)"
            [submitted]="isNgFormSubmitted()"
            [label]="'TRANSFER_FORM_BANK_PHONE' | translate"
            [id]="bankPhone + index"
            [name]="bankPhone + '_' + index"
            [controlName]="bankPhone"
            [path]="bankPhone"
            [binding]="getBindPath() + bankPhone"
            [attr]="{maxlength: 12}"
            [phoneFormat]="true"
        ></app-odc-field>

        <h3>{{'TRANSFER_FORM_ADDRESS_TITLE'|translate}}</h3>
        <app-address-form
            [legend]="'TRANSFER_FORM_ADDRESS_TITLE'|translate"
            [form]="form"
            [index]="index"
            [submitted]="isNgFormSubmitted()"
            [path]="getFormPath()+'addressGroup'"
            [bindPath]="getBindPath()"
            [street]="'addressStreet'"
            [unit]="'addressUnit'"
            [city]="'addressCity'"
            [province]="'addressProv'"
            [postalcode]="'addressPostalCode'"
            [country]="'addressCountry'"
        ></app-address-form>

        <h3>{{'TRANSFER_FORM_ACCOUNT_TITLE'|translate}}</h3>
        <app-account-form [form]="form"
                          [index]="index"
                          [ngFormRef]="ngFormRef"
                          [path]="getFormPath()+'accountGroup'"
                          [bindPath]="getBindPath()"
                          [actType]="actType"
                          [accountNumber]="'accountNumber'"
                          [accountType]="'accountType'"
                          [accountCurrency]="'accountCurrency'"
                          [accountCurrencyCAD]="'accountCurrencyCAD'"
                          [accountCurrencyUS]="'accountCurrencyUS'"
        >
        </app-account-form>

        <div *ngIf="!showAccountGroupSec && actType === 'CASH'" class="m-t-3 m-b-5">
            <a href="#" class="lien-sans-soulignement" (click)="toggleAccountGroupSec(true)">
                <span class="icon-circle-plus"></span>
                {{'TRANSFER_FORM_ACCOUNT_ADD'|translate}}
            </a>
        </div>

        <app-account-form *ngIf="showAccountGroupSec"
                          [form]="form"
                          [index]="index"
                          [ngFormRef]="ngFormRef"
                          [bindPath]="getBindPath()"
                          [actType]="actType"
                          [path]="getFormPath()+'accountGroupSec'"
                          [accountNumber]="'accountNumberSec'"
                          [accountType]="'accountTypeSec'"
                          [accountCurrency]="'accountCurrencySec'"
                          [accountCurrencyCAD]="'accountCurrencyCADSec'"
                          [accountCurrencyUS]="'accountCurrencyUSSec'"
        >

        </app-account-form>

        <div *ngIf="showAccountGroupSec && actType === 'CASH'" class="m-b-1">
            <a href="#" class="lien-sans-soulignement" (click)="toggleAccountGroupSec(false)">
                <span class="icon-close rouge"></span>
                {{'TRANSFER_FORM_ACCOUNT_REMOVE'|translate}}
            </a>
        </div>

        <h3>{{'TRANSFER_FORM_REQUEST_TITLE'|translate}}</h3>
        <fieldset>
            <legend class="sr-only">{{'TRANSFER_FORM_REQUEST_TITLE'|translate}}</legend>
            <app-odc-field
                [type]="constants.FIELD_TYPES.RADIO"
                [form]="form.get('transferGroup.' + index)"
                [submitted]="isNgFormSubmitted()"
                [label]="'TRANSFER_FORM_REQUEST_TYPE' | translate"
                [choices]="[{label: 'TRANSFER_FORM_REQUEST_TYPE_TOTAL' | translate, value: 'TOTAL'}, {label: 'TRANSFER_FORM_REQUEST_TYPE_PARTIAL' | translate, value: 'PARTIAL'}]"
                [id]="transferType + index"
                [name]="transferType + '_' + index"
                [controlName]="transferType"
                [path]="transferType"
                [binding]="getBindPath() + transferType"
            ></app-odc-field>

            <app-odc-field
                [type]="constants.FIELD_TYPES.RADIO"
                [form]="form.get('transferGroup.' + index)"
                [submitted]="isNgFormSubmitted()"
                [label]="'TRANSFER_FORM_REQUEST_INSTRUCTION' | translate"
                [choices]="[{label: 'TRANSFER_FORM_REQUEST_INSTRUCTION_CASH' | translate, value: constants.TRANSFER_INSTRUCTION_CASH}, {label: 'TRANSFER_FORM_REQUEST_INSTRUCTION_KIND' | translate, value: constants.TRANSFER_INSTRUCTION_KIND}, {label: 'TRANSFER_FORM_REQUEST_INSTRUCTION_MIXED' | translate, value: constants.TRANSFER_INSTRUCTION_MIXED}]"
                [id]="transferInstructionType + index"
                [name]="transferInstructionType + '_' + index"
                [controlName]="transferInstructionType"
                [path]="transferInstructionType"
                [binding]="getBindPath() + transferInstructionType"
            ></app-odc-field>

            <div *ngIf="displayTransferCash()">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('transferGroup.' + index)"
                    [submitted]="isNgFormSubmitted()"
                    [label]="'TRANSFER_FORM_REQUEST_TRANSFER_CASH' | translate"
                    [id]="transferCash + index"
                    [name]="transferCash + '_' + index"
                    [controlName]="transferCash"
                    [path]="transferCash"
                    [binding]="getBindPath() + transferCash"
                    [attr]="{maxlength: 32}"
                    [decimalCurrencyFormat]="true"
                ></app-odc-field>
            </div>


            <div *ngIf="displaySecurities()">
                <table class="table tableau-donnees table-striped table-condensed">
                    <caption class="h4">{{ 'TRANSFER_FORM_SECURITY_TITLE' | translate }}
                    </caption>
                    <thead>
                    <tr>
                        <th>{{'TRANSFER_FORM_SECURITY_DESCRIPTION'|translate}}</th>
                        <th>{{'TRANSFER_FORM_SECURITY_SYMBOL'|translate}}</th>
                        <th>{{'TRANSFER_FORM_SECURITY_QUANTITY'|translate}}</th>
                        <th>{{'TRANSFER_FORM_SECURITY_TYPE'|translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container formArrayName="securityGroup"
                                  *ngFor="let security of securityGroup.controls; let i=index">
                        <tr [formGroupName]="i" class="form-condensed">
                            <td>
                                <app-odc-field
                                    [type]="constants.FIELD_TYPES.TEXT"
                                    [form]="form.get('transferGroup.' + index + '.securityGroup.' + i)"
                                    [submitted]="isNgFormSubmitted()"
                                    [label]="'TRANSFER_FORM_SECURITY_DESCRIPTION' | translate"
                                    [labelSrOnly]="true"
                                    [id]="'description' + index + i"
                                    [name]="'description' + '_' + index + '_' + i"
                                    [controlName]="'description'"
                                    [path]="'description'"
                                    [nameSuffix]="index + '_' + i"
                                    [binding]="getBindPath() + 'securities.' + i + '.description'"
                                    [attr]="{maxlength: 64}"
                                ></app-odc-field>
                            </td>
                            <td>
                                <app-odc-field
                                    [type]="constants.FIELD_TYPES.TEXT"
                                    [form]="form.get('transferGroup.' + index + '.securityGroup.' + i)"
                                    [submitted]="isNgFormSubmitted()"
                                    [label]="'TRANSFER_FORM_SECURITY_SYMBOL' | translate"
                                    [labelSrOnly]="true"
                                    [id]="'symbol' + index + i"
                                    [name]="'symbol' + '_' + index + '_' + i"
                                    [controlName]="'symbol'"
                                    [path]="'symbol'"
                                    [nameSuffix]="index + '_' + i"
                                    [binding]="getBindPath() + 'securities.' + i + '.symbol'"
                                    [attr]="{maxlength: 32}"
                                ></app-odc-field>
                            </td>
                            <td>
                                <app-odc-field
                                    [type]="constants.FIELD_TYPES.TEXT"
                                    [form]="form.get('transferGroup.' + index + '.securityGroup.' + i)"
                                    [submitted]="isNgFormSubmitted()"
                                    [label]="'TRANSFER_FORM_SECURITY_QUANTITY' | translate"
                                    [labelSrOnly]="true"
                                    [id]="'quantity' + index + i"
                                    [name]="'quantity' + '_' + index + '_' + i"
                                    [controlName]="'quantity'"
                                    [path]="'quantity'"
                                    [nameSuffix]="index + '_' + i"
                                    [binding]="getBindPath() + 'securities.' + i + '.quantity'"
                                    [attr]="{maxlength: 19}"
                                    [securityQuantityFormat]="true"
                                ></app-odc-field>
                            </td>
                            <td>
                                <app-odc-field
                                    [type]="constants.FIELD_TYPES.SELECT"
                                    [form]="form.get('transferGroup.' + index + '.securityGroup.' + i)"
                                    [submitted]="isNgFormSubmitted()"
                                    [label]="'TRANSFER_FORM_SECURITY_TYPE' | translate"
                                    [labelSrOnly]="true"
                                    [id]="'type' + index + i"
                                    [name]="'type' + '_' + index + '_' + i"
                                    [controlName]="'type'"
                                    [path]="'type'"
                                    [nameSuffix]="index + '_' + i"
                                    [binding]="getBindPath() + 'securities.' + i + '.type'"
                                    [list]="securityTypeList"
                                    [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                                    [attrDisabled]="isTransferFormSecurityTypeDisabled"
                                ></app-odc-field>
                            </td>
                        </tr>
                        <tr *ngIf="securityGroup.length < 30 && securityGroup.length === (i + 1)"
                            class="no-table-striped">
                            <td colspan="4">
                                <div class="m-t-1 m-b-1">
                                    <a href="#" class="lien-sans-soulignement" (click)="addSecurity()">
                                        <span class="icon-circle-plus"></span>
                                        {{'TRANSFER_FORM_SECURITY_ADD'|translate}}
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </fieldset>

        <!--account statement-->
        <div class="form-group" [class.has-error]="isFieldInError(getFormPath()+accountStatementName)">
            <label [attr.for]="accountStatement + index">
                <b>{{'TRANSFER_FORM_ACCOUNTSTATEMENT'|translate}}</b>
                <app-error [form]="form" [submitted]="isNgFormSubmitted()" [path]="getFormPath() + accountStatementName"
                           [nameSuffix]="index"></app-error>
            </label>
            <div class="row">
                <div class="col-md-18">
                    <input type="file"
                           [attr.id]="accountStatement + index"
                           class="sr-only"
                           (change)="onFileChange($event, 'accountStatementText_'+index)"
                           accept="application/pdf"
                           formControlName={{uploadFile}}
                           name="accountStatementFile_{{index}}"
                           [attr.aria-hidden]="'true'"/>

                    <div [class.input-group]="formGroup.get('accountStatementName').value">
                        <label [attr.for]="accountStatementName + index"
                               class="sr-only">{{'TRANSFER_FORM_ACCOUNTSTATEMENT'|translate}}</label>
                        <input type="text"
                               [attr.id]="accountStatementName + index"
                               class="form-control"
                               (click)="selectFile('accountStatementFile_'+index)"
                               formControlName={{accountStatementName}}
                               name="accountStatementName_{{index}}" readonly/>
                        <span class="input-group-addon file-upload-remove"
                              *ngIf="formGroup.get('accountStatementName').value"
                              (click)="removeAccountStatement(index)"
                              tabindex="0"
                        >
                            <span class="sr-only">{{'BTN_REMOVE_FILE'|translate}}</span>
                            &times;
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <button class="btn btn-primary btn-block btn-upload"
                            (click)="selectFile('accountStatementFile_'+index)"
                            [attr.disabled]="disableWhenSaving()"
                    >
                        <span class="sr-only">{{'TRANSFER_FORM_ACCOUNTSTATEMENT'|translate}} - </span>
                        {{'TRANSFER_FORM_UPLOAD'|translate}}
                    </button>
                </div>
            </div>
        </div>

        <!--remove transfer-->
        <div *ngIf="displayRemoveTransfer()" class="m-t-2 m-b-2">
            <a href="#" class="lien-sans-soulignement" (click)="removeTransfer(actType, +index)">
                <span class="icon-close rouge"></span>
                {{'TRANSFER_FORM_REMOVE'|translate}}
            </a>
        </div>
    </fieldset>

</div>
